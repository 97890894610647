<template>
  <div style="overflow: auto">
    <div style="margin: 1%;display: flex;flex-direction: column;">
      <div style="display: flex;flex-direction: row;justify-content: left">
        <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i>
        <div style="font-size: 12px;color:grey;margin-top: -5px">培养方案</div>
        <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i>
        <div style="font-size: 12px;color:grey;margin-top: -5px">培养目标</div>
      </div>
      <div style="display: flex;flex-direction: column;margin-top: 5px;background-color: white;">
        <div style="display: flex;flex-direction: row;justify-content: space-between;">
          <h3 style="text-align: left;color: #2D2F33;margin-left:15px;margin-top: 10px">培养目标概述</h3>
          <el-button type="primary" @click="submitTrainingObjectiveSummary" style="margin-right:15px;margin-top: 10px; height: 70%;background-color: #71246B">保存</el-button>
        </div>
        <textarea v-model="majorInfo.training_objective_summary" style="margin-left:15px;border-radius:10px;background-color:white;width:96%;height: 20vh;padding: 10px;resize: none;font-size: 18px;line-height: 2;color: #5A5E66" placeholder="请输入本专业的培养目标概述"></textarea>
      </div>
      <div style="display: flex;flex-direction: column;margin-top: 5px;background-color: white;flex-grow: 1;overflow-y: auto;">
        <div style="display: flex;flex-direction: row;justify-content: space-between;">
          <h3 style="text-align: left;color: #2D2F33;margin-left:15px;margin-top: 10px">本专业毕业生毕业五年左右应达到以下目标：</h3>
          <div>
            <el-button type="primary" @click="addTrainingObjective" style="margin-top: 10px; height: 70%;background-color: #71246B">增加</el-button>
            <el-button type="primary" @click="deleteTrainingObjective" style="margin-top: 10px; height: 70%;background-color: #71246B">减少</el-button>
            <el-button type="primary" @click="submitTrainingObjective" style="margin-right:15px;margin-top: 10px; height: 70%;background-color: #71246B">保存</el-button>
          </div>
        </div>
        <el-collapse v-model="activeCollapse" style="overflow: auto;height: 380px">
          <el-collapse-item v-for="(objective, index) in training_objective" :key="objective.id" :title="'培养目标' + (index + 1)" :name="index.toString()" style="margin-left: 15px;color: #2D2F33;">
            <textarea v-model="training_objective[index].details" style="margin-top: 2%;outline: none;resize: none; border:none; background-color:white;width: 98%; height: 100%;font-size: 14px;line-height: 1.5;color: #5A5E66" :placeholder="'请输入培养目标' + (index + 1)"></textarea>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeCollapse: ['0'],
      majorInfo: {
        major_id: localStorage.getItem('major_id'),
        training_objective_summary: '',
        enrollment_year: localStorage.getItem('enrollment_year'),
      },
      training_objective: [{}],
    };
  },
  created() {
    this.$http.post("/getTrainingObjectiveSummary", { major_id: this.majorInfo.major_id, enrollment_year: this.majorInfo.enrollment_year })
        .then(response => {
          if (response.data.data) {
            this.majorInfo.training_objective_summary = response.data.data.training_objective_summary;
          }
        }).catch(() => {
      this.$message.error("获取培养目标概述失败！");
    });
    this.$http.post("/getTrainingObjective", { major_id: this.majorInfo.major_id, enrollment_year: this.majorInfo.enrollment_year })
        .then(response => {
          this.training_objective = response.data.data.training_objective || [{}];
        }).catch(() => {
      this.$message.error("获取培养目标失败！");
    });
  },
  methods: {
    refresh() {
      this.$http.post("/getTrainingObjectiveSummary", { major_id: this.majorInfo.major_id, enrollment_year: this.majorInfo.enrollment_year })
          .then(response => {
            this.majorInfo.training_objective_summary = response.data.data.training_objective_summary;
          }).catch(() => {
        this.$message.error("获取培养目标概述失败！");
      });
      this.$http.post("/getTrainingObjective", { major_id: this.majorInfo.major_id, enrollment_year: this.majorInfo.enrollment_year })
          .then(response => {
            this.training_objective = response.data.data.training_objective || [{}];
          }).catch(() => {
        this.$message.error("获取培养目标失败！");
      });
    },
    submitTrainingObjectiveSummary() {
      this.$http.post("/submitTrainingObjectiveSummary", { major_id: this.majorInfo.major_id, training_objective_summary: this.majorInfo.training_objective_summary, enrollment_year: this.majorInfo.enrollment_year })
          .then(response => {
            this.$message.success("保存成功！");
            this.refresh();
          }).catch(() => {
        this.$message.error("保存失败！");
      });
    },
    submitTrainingObjective() {
      // 遍历 training_objective 数组并检查 id
      this.training_objective.forEach(obj => {
        if (typeof obj === 'object' && obj !== null) {
          if (!obj.id) {
            obj.id = -1;
          }
        }
      });

      this.$http.post("/submitTrainingObjective", { major_id: this.majorInfo.major_id, training_objective: this.training_objective, enrollment_year: this.majorInfo.enrollment_year })
          .then(response => {
            this.$message.success("保存成功！");
            this.refresh();
          }).catch(() => {
        this.$message.error("保存失败");
      });
      this.$http.post("/finishTrainingObjective",{major_id: this.majorInfo.major_id})
    },
    addTrainingObjective() {
      this.training_objective.push({ details: '', id: null });
      this.activeCollapse.push(this.training_objective.length - 1);
    },
    deleteTrainingObjective() {
      if (this.training_objective.length > 1) {
        this.training_objective.pop();
        this.activeCollapse.pop();
      } else {
        this.$message.error("至少需要一个培养目标！");
      }
    }
  }
};
</script>
<style>
.el-collapse-item__header {
  font-size: 14px;
  color: #2D2F33;
}
.el-collapse-item__wrap {
}
</style>
