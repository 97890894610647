<template>
  <div id="home">
    <div class="menu">

      <el-steps :active="active" process-status="wait" finish-status="success" align-center style="margin-top:3%;margin-bottom:3%;width: 80%;">
        <el-step :description="active === 1 ? '验证账号' : ''"></el-step>
        <el-step :description="active === 2 ? '验证邮箱' : ''"></el-step>
        <el-step :description="active === 3 ? '修改密码' : ''"></el-step>
      </el-steps>

      <el-form class="input" style="margin-top: 3%">
        <div v-show="active === 1">
          <el-form-item prop="employee_id">
          <el-input class="custom_input" v-model="loginUser.employee_id" prefix-icon="el-icon-user" placeholder="请输入工号"></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input class="custom_input" v-model="loginUser.email" prefix-icon="iconfont icon-youxiang" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item prop="verification_code">
            <el-form style="display: flex; align-items: center;">
              <el-input class="custom_input" prefix-icon="el-icon-key" v-model="code" placeholder="验证码" style="flex: 1; margin-right: 10px;"></el-input>
              <div>
                <img :src="captchaUrl" alt="验证码" @click="refreshCaptcha" style="cursor: pointer; width: 80px; height: 40px;margin-bottom: -10px">
              </div>
            </el-form>
          </el-form-item>
          <el-form-item>
            <div>
              <el-button type="text" @click="verify1" style="border-radius: 10px; background-color: #71246B; color: white; width: 100%">验证</el-button>
            </div>
          </el-form-item>
          <el-form-item style="margin-top:-12%">
            <router-link to="/" style="font-size: 10px; text-decoration: none; color: #808080;">返回上一级</router-link>
          </el-form-item>
        </div>
        <div v-show="active === 2">
          <el-form-item prop="email_code">
            <el-input class="custom_input" v-model="email_code" prefix-icon="el-icon-key" placeholder="请输入邮箱验证码"></el-input>
          </el-form-item>
          <el-form-item>
            <div>
              <el-button type="text" @click="verify2" style="border-radius: 10px; background-color: #71246B; color: white; width: 100%">验证</el-button>
            </div>
          </el-form-item>
          <el-form-item style="margin-top:-12%">
            <div @click="active = 1" style="font-size: 10px; text-decoration: none; color: #808080;cursor: pointer">返回上一级</div>
          </el-form-item>
        </div>
        <div v-show="active === 3">
          <el-form-item prop="password1">
            <el-input :type="type1" class="custom_input" v-model="password1" prefix-icon="el-icon-lock" placeholder="请输入密码"><i slot="suffix" class="icon-style" :class="elIcon1" @click="flag1 = !flag1"/></el-input>
          </el-form-item>
          <el-form-item prop="password2">
            <el-input :type="type2" class="custom_input" v-model="password2" prefix-icon="el-icon-lock" placeholder="请再次输入密码"><i slot="suffix" class="icon-style" :class="elIcon2" @click="flag2 = !flag2"/></el-input>
          </el-form-item>
          <el-form-item>
            <div>
              <el-button type="text" @click="verify3" style="border-radius: 10px; background-color: #71246B; color: white; width: 100%">修改</el-button>
            </div>
          </el-form-item>
          <el-form-item style="margin-top:-12%">
            <div @click="active = 2" style="font-size: 10px; text-decoration: none; color: #808080;cursor: pointer">返回上一级</div>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      active: 1,
      loginUser:{
        email:'',
        employee_id: '',
        user_password: ''
      },
      captchaUrl: '',
      codeId:'',
      code:'',
      email_code:'',//邮箱验证码
      password1:'',//第一次输入的密码
      password2:'',//第二次输入的密码
      flag1:false,
      flag2:false,
    };
  },

  methods: {
    refreshCaptcha() {
      // 调用后端接口获取验证码
      this.$http.get('/forget/captcha', {
        responseType: 'arraybuffer'  // 告诉axios返回的数据为二进制数据
      }).then(response => {
        // 从响应头获取codeId
        this.codeId = response.headers.get('codeId');

        // 将返回的二进制数据转为base64格式
        const imageData = btoa(
            new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this.captchaUrl = 'data:image/jpeg;base64,' + imageData;

      }).catch(error => {
        this.$message.error('获取验证码失败');
      });
    },
    // 验证邮箱和工号以及验证码是否正确，如果全部正确并且成功发送邮箱返回1，全部正确但是发送邮箱错误返回2，有错误返回0
    verify1(){
      this.$http.post("/forget/verify/user", {employee_id:this.loginUser.employee_id,email:this.loginUser.email,codeId:this.codeId,code:this.code})
          .then(response => {
            if (response.data.message === '验证成功') {
              this.codeId = response.data.data.codeId;
              this.active = 2;
              this.$message.success('已发送邮箱');
            } else {
              this.$message.error(response.data.message);
            }
          })
    },
    // 验证邮箱验证码
    verify2(){
        this.$http.post("/forget/verify/email", {codeId:this.codeId,code:this.email_code})
            .then(response => {
              if (response.data.message === '验证成功') {
                this.codeId = response.data.data.codeId;
                this.active = 3;
                this.$message.success('邮箱验证成功');
              } else {
                this.$message.error(response.data.message);
              }
            })
    },
    //修改密码
    verify3(){
      if(this.password1 !== this.password2){
        this.password1 = '';
        this.password2 = '';
        this.$message.error('两次输入密码不同！');
      }
      else{
        if (this.password1.length < 6) {
          this.$message.error('密码长度过短，请至少输入6位');
        } else {
          this.$http.post("/forget/reset/password", {codeId:this.codeId,new_password:this.password1,confirm_password:this.password2,employee_id:this.loginUser.employee_id})
              .then(response => {
                if (response.data.message === "修改成功") {
                  this.$message.success('密码设置成功');
                  this.$router.push('/');
                } else {
                  this.$message.error(response.data.message);
                  this.password1 = '';
                  this.password2 = '';
                }
              })
              .catch(error => {
                this.$message.error('修改失败!');
                console.error(error)
              });
        }
      }
    }
  },
  mounted() {
    this.refreshCaptcha(); // 页面挂载后再获取验证码图片
    document.addEventListener('keydown', this.handleKeyDown);
  },
  computed: {
    type1() {
      return this.flag1 ? "text" : "password";
    },
    elIcon1() {
      return this.flag1 ? "el-icon-view" : "iconfont icon-biyan";
    },
    type2() {
      return this.flag2 ? "text" : "password";
    },
    elIcon2() {
      return this.flag2 ? "el-icon-view" : "iconfont icon-biyan";
    }
  }
}
</script>

<style>
#home {
  width: 100%;
  height: 100vh;
  background: url("@/assets/picture/login.jpg") center center no-repeat;
  background-size: 100% 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu{
  background-color: white;
  width: 50%;
  height: 54%;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 添加阴影 */
  border-radius: 10px; /* 添加圆角 */
}
.input{
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom_input input{
  border-color: #8F8F8F;
  border-radius: 10px;
}
</style>
