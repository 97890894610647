<template>
  <div style="margin: 1%;display: flex;flex-direction: column">
    <div style="display: flex;flex-direction: row;justify-content: left">
      <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i>
      <div style="font-size: 12px;color:grey;margin-top: -5px">人员管理</div>
    </div>
    <div style="background-color: white;margin-top: 5px;">
      <div>
        <el-table
            ref="tableData"
            :data="employeeList"
            border
            :height="tableHeight"
            :max-height="tableHeight"
            style="width: 98%; margin-top: 20px; margin-left: 15px;">
          <el-table-column
              fixed
              prop="employee_id"
              label="工号">
          </el-table-column>
          <el-table-column
              prop="user_name"
              label="姓名">
          </el-table-column>
          <el-table-column
              prop="role_"
              label="权限类型"
              sortable>
            <template slot-scope="scope">
              <span v-if="scope.row.role_ == 2">院负责人</span>
              <span v-else-if="scope.row.role_ == 3">系主任</span>
              <span v-else>教务处</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="college_name"
              label="所属学院"
              sortable>
          </el-table-column>
          <el-table-column
              prop="major_CN_name"
              label="所属专业">
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="200">
            <template slot-scope="scope">
              <el-button @click="handleUpdate(scope)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
              <el-button @click="handleDelete(scope)" type="text" size="small" icon="el-icon-delete" style="color: red">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 8vh; display: flex;flex-direction: row;background-color: white;justify-content: right">
      <el-button type="primary" @click="handleAdd" style="margin-right:15px;width: 100px; height: 80%;background-color: #71246B">新增</el-button>
    </div>
    <el-dialog title="新增用户" :visible.sync="addFormVisible" :modal="true" :modal-append-to-body="false">
      <el-form>
        <el-form-item label="工号">
          <el-input v-model="addEmployee.employee_id" placeholder="请输入工号"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="addEmployee.user_name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="权限类型">
          <el-select v-model="addEmployee.role_" placeholder="请选择权限类型">
            <el-option label="院负责人" value="2"></el-option>
            <el-option label="系负责人" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属学院">
          <el-select v-model="addEmployee.college_id" placeholder="请选择学院">
            <el-option
                v-for="college in collegeList"
                :key="college.college_id"
                :label="college.college_name"
                :value="college.college_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属专业" v-if="addEmployee.role_ == '3'">
          <el-select v-model="addEmployee.major_id" placeholder="请选择专业">
            <el-option
                v-for="major in filteredMajors"
                :key="major.major_id"
                :label="major.major_CN_name"
                :value="major.major_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitAddEmployee" style="background-color: #71246B">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="编辑用户" :visible.sync="editFormVisible" :modal="true" :modal-append-to-body="false">
      <el-form>
        <el-form-item label="工号">
          <el-input v-model="editEmployee.employee_id" placeholder="请输入工号"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="editEmployee.user_name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="权限类型">
          <el-select v-model="editEmployee.role_" placeholder="请选择权限类型">
            <el-option label="院负责人" value="2"></el-option>
            <el-option label="系负责人" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属学院">
          <el-select v-model="editEmployee.college_id" placeholder="请选择学院">
            <el-option
                v-for="college in collegeList"
                :key="college.college_id"
                :label="college.college_name"
                :value="college.college_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属专业" v-if="editEmployee.role_ == '3'">
          <el-select v-model="editEmployee.major_id" placeholder="请选择专业">
            <el-option
                v-for="major in filteredMajors"
                :key="major.major_id"
                :label="major.major_CN_name"
                :value="major.major_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitEditEmployee" style="background-color: #71246B">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addFormVisible: false,
      editFormVisible: false,
      addEmployee: {
        employee_id: '',
        user_name: '',
        role_: '',
        college_id: '',
        major_id: ''
      },
      editEmployee: {
        employee_id: '',
        user_name: '',
        role_: '',
        college_id: '',
        major_id: ''
      },
      collegeList: [],
      major: [],
      employeeList: [],
      tableHeight: null,
    }
  },
  created() {
    this.fetchEmployeeList();
    this.fetchCollegeAndMajorList();
  },
  computed: {
    filteredMajors() {
      return this.major.filter(m => m.college_id === (this.addFormVisible ? this.addEmployee.college_id : this.editEmployee.college_id));
    }
  },
  mounted() {
    this.tableHeight = window.innerHeight - 150;
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.tableData) {
        this.$refs.tableData.doLayout();
      }
    });
  },
  methods: {
    fetchEmployeeList() {
      this.$http.get('/getEmployeeList')
          .then(response => {
            this.employeeList = response.data.data.employeeList;
          }).catch(() => {
        this.$message.error('获取数据失败');
      });
    },
    fetchCollegeAndMajorList() {
      this.$http.get('/getCollegeAndMajorList')
          .then(response => {
            this.collegeList = response.data.data.collegeList;
            this.major = response.data.data.majorList;
          }).catch(() => {
        this.$message.error('获取学院和专业列表失败');
      });
    },
    handleAdd() {
      this.addFormVisible = true;
    },
    submitAddEmployee() {
      this.$http.post('/addEmployee', { employee: this.addEmployee })
          .then(response => {
            this.$message.success('新增成功！');
            this.addFormVisible = false;
            this.resetAddForm();
            this.fetchEmployeeList(); // 刷新列表
          }).catch(() => {
        this.$message.error('新增失败');
      });
    },
    handleUpdate(scope) {
      this.editFormVisible = true;
      this.editEmployee = { ...scope.row };
    },
    submitEditEmployee() {
      this.$http.post('/editEmployee', { employee: this.editEmployee })
          .then(response => {
            this.$message.success('编辑成功！');
            this.editFormVisible = false;
            this.resetEditForm();
            this.fetchEmployeeList(); // 刷新列表
          }).catch(() => {
        this.$message.error('编辑失败');
      });
    },
    handleDelete(scope) {
      this.$confirm('此操作将永久删除该用户信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/deleteEmployee', { employee_id: scope.row.employee_id })
            .then(response => {
              this.$message.success('删除成功！');
              this.fetchEmployeeList(); // 刷新列表
            }).catch(() => {
          this.$message.error('删除失败');
        });
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
    resetAddForm() {
      this.addEmployee = {
        employee_id: '',
        user_name: '',
        role_: '',
        college_id: '',
        major_id: ''
      };
    },
    resetEditForm() {
      this.editEmployee = {
        employee_id: '',
        user_name: '',
        role_: '',
        college_id: '',
        major_id: ''
      };
    }
  }
}
</script>

<style>
.el-select {
  width: 206px;
}
.el-table {
  overflow: visible !important;
}
</style>
