<template>
  <div style="margin: 1%;display: flex;flex-direction: column">
    <div style="display: flex;flex-direction: row;justify-content: left">
      <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i><div style="font-size: 12px;color:grey;margin-top: -5px">课程</div>
    </div>
    <div style="background-color: white;margin-top: 5px;">
      <el-table
          :data="filteredCourses"
          border
          show-summary
          ref="tableData"
          :summary-method="getSummaries"
          stripe
          :max-height="tableHeight"
          :height="tableHeight">
        <el-table-column
            fixed
            prop="course_name"
            label="课程名称"
            >
        </el-table-column>
        <el-table-column
            prop="course_id"
            label="课程编号"

            sortable>
        </el-table-column>
        <el-table-column
            prop="course_type"
            label="课程类别"

            sortable>
        </el-table-column>
        <el-table-column
            prop="course_nature"
            label="课程性质"

            sortable>
        </el-table-column>
        <el-table-column
            prop="semester"
            label="开课学期"
            width="50"
            sortable>
        </el-table-column>
        <el-table-column
            prop="credits"
            label="总学分"
            width="50"
            >
        </el-table-column>
        <el-table-column
            prop="practice_credit"
            label="实践学分"
            width="50"
          >
        </el-table-column>
        <el-table-column
            prop="total_duration"
            label="总学时"
            width="50"
            >
        </el-table-column>
        <el-table-column
            prop="theoretical_duration"
            label="理论学时"
            width="50"
          >
        </el-table-column>
        <el-table-column
            prop="practice_duration"
            label="实践学时"
            width="50"
        >
        </el-table-column>
        <el-table-column
            prop="experiment_duration"
            label="实验学时"
            width="50"
        >
        </el-table-column>
        <el-table-column
            prop="design_duration"
            label="设计学时"
            width="50"
        >
        </el-table-column>
        <el-table-column
            prop="is_central_exam"
            label="是否集中考试"
            width="50"
         >
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="200"
         >
          <template slot-scope="scope">
            <el-button @click="handleUpdate(scope)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
            <el-button @click="handleDelete(scope)" type="text" size="small" icon="el-icon-delete" style="color: red">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div style="display: flex;flex-direction: row-reverse;justify-content: space-between">
        <div style="width:16%;display: flex;flex-direction: row; justify-content: space-between;margin-top:15px">
          <el-button type="primary" @click="handleAddCourse" style="width: 50%;height: 80%;background-color: #71246B">新增</el-button>
          <el-button type="primary" @click="submitCourses" style="width: 50%;height: 80%;background-color: #71246B">保存</el-button>
        </div>
        <div style="display: flex;flex-direction: row">
          <h3>按学期筛选：</h3>
          <el-select v-model="selectSemester" style="margin-top: 15px">
            <el-option v-for="semester in semesterList" :key="semester.semester_id" :value="semester.semester_id" :label="semester.semester_name"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <el-dialog title="新增课程" :visible.sync="addFormVisible" :modal="true" :modal-append-to-body="false">
      <el-form :inline="true">
        <el-form-item label="课程名称" style="margin-right: 30px">
          <el-input placeholder="请输入课程名称" v-model="addCourse.course_name"></el-input>
        </el-form-item>
        <el-form-item label="课程编号" style="margin-left: 30px">
          <el-input placeholder="请输入课程编号" v-model="addCourse.course_id"></el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="课程类别" style="margin-right: 30px">
          <el-select v-model="addCourse.course_type" placeholder="请选择课程类别">
            <el-option v-for="course_type in courseTypeList" :label="course_type" :value="course_type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程性质" style="margin-left: 30px">
          <el-select v-model="addCourse.course_nature" placeholder="请选择课程性质">
            <el-option v-for="course_nature in courseNatureList" :label="course_nature" :value="course_nature"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="总学分数" style="margin-right: 30px">
          <el-input placeholder="请输入总学分" v-model="addCourse.credits"></el-input>
        </el-form-item>
        <el-form-item label="实践学分" style="margin-left: 30px">
          <el-input placeholder="请输入实践学分" v-model="addCourse.practice_credit"></el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="总学时数" style="margin-right: 30px">
          <el-input placeholder="请输入总学分" v-model="addCourse.total_duration"></el-input>
        </el-form-item>
        <el-form-item label="集中考试" style="margin-left: 30px">
          <el-select v-model="addCourse.is_central_exam" placeholder="请选择是否集中考试">
            <el-option v-for="is_central_exam in ['是','否']" :label="is_central_exam" :value="is_central_exam"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="理论学时" style="margin-right: 30px">
          <el-input placeholder="请输入理论学时" v-model="addCourse.theoretical_duration"></el-input>
        </el-form-item>
        <el-form-item label="实践学时" style="margin-left: 30px">
          <el-input placeholder="请输入实践学时" v-model="addCourse.practice_duration"></el-input>
        </el-form-item>
        <el-form-item label="实验学时" style="margin-right: 30px">
          <el-input placeholder="请输入实验学时" v-model="addCourse.experiment_duration"></el-input>
        </el-form-item>
        <el-form-item label="设计学时" style="margin-left: 30px">
          <el-input placeholder="请输入设计学时" v-model="addCourse.design_duration"></el-input>
        </el-form-item>
      </el-form>
      <el-form>
        <el-form-item label="请选择开课学期">
          <el-radio-group v-model="addCourse.semester">
            <el-radio :label="1">1</el-radio>
            <el-radio :label="2">2</el-radio>
            <el-radio :label="3">3</el-radio>
            <el-radio :label="4">4</el-radio>
            <el-radio :label="5">5</el-radio>
            <el-radio :label="6">6</el-radio>
            <el-radio :label="7">7</el-radio>
            <el-radio :label="8">8</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin-top: 20px;">
          <el-button type="primary" @click="submitAddCourse" style="background-color: #71246B">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="编辑课程" :visible.sync="editFormVisible" :modal="true" :modal-append-to-body="false">
      <el-form :inline="true" style="display: flex; justify-content: center">
        <div style="display: flex; justify-content: space-between; width: 90%">
          <el-form-item label="课程名称" style="flex: 1; margin-right: 10px">
            <el-input placeholder="请输入课程名称" v-model="editCourse.course_name" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="课程编号" style="flex: 1">
            <el-input placeholder="请输入课程编号" v-model="editCourse.course_id" style="width: 100%"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <el-form :inline="true" style="display: flex; justify-content: center">
        <div style="display: flex; justify-content: space-between; width: 90%">
          <el-form-item label="课程类别" style="flex: 1; margin-right: 10px">
            <el-select v-model="editCourse.course_type" placeholder="请选择课程类别" style="width: 93%">
              <el-option v-for="course_type in courseTypeList" :label="course_type" :value="course_type"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程性质" style="flex: 1">
            <el-select v-model="editCourse.course_nature" placeholder="请选择课程性质" style="width: 93%">
              <el-option v-for="course_nature in courseNatureList" :label="course_nature" :value="course_nature"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <el-form :inline="true" style="display: flex; justify-content: center">
        <div style="display: flex; justify-content: space-between; width: 90%">
          <el-form-item label="总学分数" style="flex: 1; margin-right: 10px">
            <el-input placeholder="请输入总学分" v-model="editCourse.credits" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="实践学分" style="flex: 1">
            <el-input placeholder="请输入实践学分" v-model="editCourse.practice_credit" style="width: 100%"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <el-form :inline="true" style="display: flex; justify-content: center">
        <div style="display: flex; justify-content: space-between; width: 90%">
          <el-form-item label="总学时数" style="flex: 1; margin-right: 10px">
            <el-input placeholder="请输入总学时数" v-model="editCourse.total_duration" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="集中考试" style="flex: 1">
            <el-select v-model="editCourse.is_central_exam" placeholder="请选择是否集中考试" style="width: 93%">
              <el-option v-for="is_central_exam in ['是','否']" :label="is_central_exam" :value="is_central_exam"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <el-form :inline="true" style="display: flex; justify-content: center">
        <div style="display: flex; justify-content: space-between; width: 90%">
          <el-form-item label="理论学时" style="flex: 1; margin-right: 10px">
            <el-input placeholder="请输入理论学时" v-model="editCourse.theoretical_duration" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="实践学时" style="flex: 1; margin-right: 10px">
            <el-input placeholder="请输入实践学时" v-model="editCourse.practice_duration" style="width: 100%"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <el-form :inline="true" style="display: flex; justify-content: center">
        <div style="display: flex; justify-content: space-between; width: 90%">
          <el-form-item label="实验学时" style="flex: 1; margin-right: 10px">
            <el-input placeholder="请输入实验学时" v-model="editCourse.experiment_duration" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label="设计学时" style="flex: 1">
            <el-input placeholder="请输入设计学时" v-model="editCourse.design_duration" style="width: 100%"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <el-form style="margin-left: 5%">
        <el-form-item label="请选择开课学期">
          <el-radio-group v-model="editCourse.semester">
            <el-radio :label="1">1</el-radio>
            <el-radio :label="2">2</el-radio>
            <el-radio :label="3">3</el-radio>
            <el-radio :label="4">4</el-radio>
            <el-radio :label="5">5</el-radio>
            <el-radio :label="6">6</el-radio>
            <el-radio :label="7">7</el-radio>
            <el-radio :label="8">8</el-radio>
          </el-radio-group>
        </el-form-item>
    <el-form-item style="margin-top: 20px;">
          <el-button type="primary" @click="submitEditCourse" style="background-color: #71246B">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableHeight:null,
      selectSemester:0,
      semesterList:[
        {
          semester_id:0,
          semester_name:'全部学期'
        },
        {
          semester_id:1,
          semester_name:'第一学期'
        },
        {
          semester_id:2,
          semester_name:'第二学期'
        },
        {
          semester_id:3,
          semester_name:'第三学期'
        },
        {
          semester_id:4,
          semester_name:'第四学期'
        },
        {
          semester_id:5,
          semester_name:'第五学期'
        },
        {
          semester_id:6,
          semester_name:'第六学期'
        },
        {
          semester_id:7,
          semester_name:'第七学期'
        },
        {
          semester_id:8,
          semester_name:'第八学期'
        },
      ],
      courseTypeList:['通识必修课A','学科基础课B','专业基础课C','专业选修课E','专业方向课Z'],
      courseNatureList:['思想政治理论课','选择性必修课','必修','任选'],
      addCourse:{
        major_id:localStorage.getItem('major_id'),
        enrollment_year:localStorage.getItem('enrollment_year'),
        course_name: '',
        course_id: '',
        course_type: '',
        course_nature: '',
        credits: '',
        practice_credit: '',
        total_duration: '',
        theoretical_duration: '',
        practice_duration: '',
        experiment_duration: '',
        design_duration: '',
        is_central_exam: '',
        semester:''
      },
      oldCourseId:'',
      editCourse:{
        major_id:localStorage.getItem('major_id'),
        enrollment_year:localStorage.getItem('enrollment_year'),
        course_name: '',
        course_id: '',
        course_type: '',
        course_nature: '',
        credits: '',
        practice_credit: '',
        total_duration: '',
        theoretical_duration: '',
        practice_duration: '',
        experiment_duration: '',
        design_duration: '',
        is_central_exam: '',
        semester:''
      },
      courses:[],
      addFormVisible:false,
      editFormVisible:false,
    }
  },
  computed: {
    filteredCourses() {
      // 如果 selectSemester 为 0，则不需要筛选，直接返回全部课程
      if (this.selectSemester === 0) {
        return this.courses;
      } else {
        // 否则，根据 selectSemester 的值筛选出符合条件的课程
        return this.courses.filter(course => course.semester === this.selectSemester);
      }
    }
  },
  mounted() {
    console.log(window.innerHeight)
    this.tableHeight = window.innerHeight - 150;
    // 后面的50：根据需求空出的高度，自行调整
  },
  updated() {
    this.$nextTick(()=>{
      this.$refs.tableData.doLayout();
    })
  },
  created() {
    this.$http.post('/getCourses',{major_id:localStorage.getItem('major_id'),enrollment_year:localStorage.getItem('enrollment_year')})
        .then(response=>{
          this.courses = response.data.data.courses
        }).catch('获取课程列表失败！')
  },
  methods: {
    refresh() {
      this.$http.post('/getCourses',{major_id:localStorage.getItem('major_id'),enrollment_year:localStorage.getItem('enrollment_year')})
          .then(response=>{
            this.courses = response.data.data.courses
          }).catch('获取课程列表失败！')
    },
    handleAddCourse(){
      this.addFormVisible = true;
    },
    submitAddCourse(){
      this.$http.post("/submitAddCourse",{course:this.addCourse})
          .then(response=>{
            this.addFormVisible=false
            this.$message.success('保存成功！')
            this.addCourse = {
              major_id:localStorage.getItem('major_id'),
              enrollment_year:localStorage.getItem('enrollment_year'),
              course_name: '',
              course_id: '',
              course_type: '',
              course_nature:'',
              credits: '',
              practice_credit: '',
              total_duration: '',
              theoretical_duration: '',
              practice_duration: '',
              experiment_duration: '',
              design_duration: '',
              is_central_exam: '',
              semester:''
            }
            this.refresh()
          }).catch(error=>{
        this.$message.error("保存失败!")
      })
    },
    submitCourses(){
      this.$http.post('/finishCourses',{major_id:localStorage.getItem('major_id')})
          .then(response=>{
            this.$message.success('保存成功！')
          }).catch(error=>{
        this.$message.error('保存失败！')
      })
    },
    handleUpdate(scope) {
      this.editFormVisible = true;
      this.editCourse = this.courses.find(course => course.course_id === scope.row.course_id)
      this.oldCourseId = scope.row.course_id
    },
    submitEditCourse(){
      // 检查是否修改了主键 course_id
      if (this.editCourse.course_id !== this.oldCourseId) {
        // 如果修改了主键，需要先删除旧的数据再插入新的数据
        this.$http.post("/editCourseAndCourseId", {
          oldCourseId: this.oldCourseId, // 旧的 course_id
          course: this.editCourse // 更新后的课程信息
        }).then(response => {
          // 更新成功后的处理
          this.editFormVisible = false;
          this.$message.success('课程编辑成功！');
          this.refresh(); // 刷新课程列表
        }).catch(error => {
          // 更新失败时的处理
          this.$message.error('课程编辑失败！');
        });
      } else {
        // 如果没有修改主键，只需要更新其他属性
        this.$http.post("/editCourse", this.editCourse)
            .then(response => {
              this.editFormVisible = false;
              this.$message.success('课程编辑成功！');
              this.refresh(); // 刷新课程列表
            }).catch(error => {
          this.$message.error('课程编辑失败！');
        });
      }
    },
    handleDelete(scope) {
      this.$confirm('此操作将永久删除该课程信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 在这里执行删除操作
        this.$http.post("/deleteCourse", {major_id:localStorage.getItem('major_id'), course_id: scope.row.course_id })
            .then(response => {
              this.$message.success('删除成功！');
              this.refresh()
            })
            .catch(error => {
              this.$message.error('删除失败！');
            });
      }).catch(error => {
      })
    },
    //指定列求和
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        // 对指定列进行求和操作
        if (column.property === 'credits' || column.property === 'practice_credit' || column.property === 'total_duration' || column.property === 'theoretical_duration' || column.property === 'practice_duration' || column.property === 'experiment_duration' || column.property === 'design_duration') {
          const values = data.map(item => Number(item[column.property]));
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (column.property === 'credits' || column.property === 'practice_credit') {
            sums[index] = sums[index].toFixed(2); // 保留两位小数
          }
        }
      });
      return sums;
    }
  },
}
</script>
<style>
.el-select{
  width:206px;
}
.el-table {   overflow: visible !important; }

</style>
