<template>
  <div style="margin: 1%;display: flex;flex-direction: column">
    <div style="display: flex;flex-direction: row;justify-content: left">
      <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i><div style="font-size: 12px;color:grey;margin-top: -5px">学院与专业管理</div>
    </div>
    <div style="background-color: white;margin-top: 5px">
      <div>
        <el-table
            :data="processedCollege"
            border
            :height="tableHeight"
            :max-height="tableHeight"
            style="width: 98%; margin-top: 20px; margin-left: 15px;"
        >
          <el-table-column prop="college_name" label="学院名称"></el-table-column>
          <el-table-column label="开设专业" >
            <template slot-scope="scope">
              <el-table :data="scope.row.major" :row-class-name="rowClassName" :show-header="false">
                <el-table-column prop="major_CN_name"></el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button @click="handleUpdate(scope)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
              <el-button @click="handleDelete(scope)" type="text" size="small" icon="el-icon-delete" style="color: red">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 8vh; display: flex;flex-direction: row;background-color: white;justify-content: right">
      <el-button type="primary" @click="handleAdd" style="margin-top:5px; margin-right:15px;width: 100px; height: 70%;background-color: #71246B">新增</el-button>
      <el-dialog title="新增学院与专业" :visible.sync="addCollegeFormVisible" :modal="true" :modal-append-to-body="false">
        <el-form>
          <el-form-item>
            <h3 style="text-align: left;margin-left: 15px;margin-top: 0">学院代号</h3>
            <el-input placeholder="请输入学院代号" v-model="addCollege.college_id"></el-input>
          </el-form-item>
          <el-form-item>
            <h3 style="text-align: left;margin-left: 15px;margin-top: 0">学院名称</h3>
            <el-input placeholder="请输入学院名称" v-model="addCollege.college_name"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="display: flex;flex-direction: row;justify-content: space-between">
              <h3 style="text-align: left;margin-left: 15px;margin-top: 0">开设专业</h3>
              <el-button type="primary" @click="handleAddAddMajor"  style="background-color: #71246B;height: 80%">新增专业</el-button>
            </div>
            <el-form-item v-for="(major, index) in addMajor" :key="index" style="margin-top: 5px;margin-bottom: 5px">
              <div style="display: flex;flex-direction: row;justify-content: space-between">
                <el-input style="width: 49%" :placeholder="`请输入专业代号${index + 1}`" v-model="addMajor[index].major_id">
                </el-input>
                <el-input style="width: 49%" :placeholder="`请输入专业名称${index + 1}`" v-model="addMajor[index].major_CN_name">
                  <template slot="append">
                    <i class="el-icon-delete" style="color: red" @click="handleDeleteAddMajor(index)"></i>
                  </template>
                </el-input>
              </div>
            </el-form-item>
          </el-form-item>
          <el-form-item style="margin-top: 20px;">
            <el-button type="primary" @click="AddCollegeAndMajor"  style="background-color: #71246B">提交</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="编辑学院与专业" :visible.sync="editCollegeFormVisible" :modal="true" :modal-append-to-body="false">
        <el-form>
          <el-form-item>
            <h3 style="text-align: left;margin-left: 15px;margin-top: 0">学院代号</h3>
            <el-input placeholder="请输入学院代号" v-model="editCollege.college_id"></el-input>
          </el-form-item>
          <el-form-item>
            <h3 style="text-align: left;margin-left: 15px;margin-top: 0">学院名称</h3>
            <el-input placeholder="请输入学院名称" v-model="editCollege.college_name"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="display: flex;flex-direction: row;justify-content: space-between">
              <h3 style="text-align: left;margin-left: 15px;margin-top: 0">开设专业</h3>
              <el-button type="primary" @click="handleAddEditMajor"  style="background-color: #71246B;height: 80%">新增专业</el-button>
            </div>
            <el-form-item v-for="(major, index) in editMajor" :key="index" style="margin-top: 5px;margin-bottom: 5px">
              <div style="display: flex;flex-direction: row;justify-content: space-between">
                <el-input style="width: 49%" :placeholder="`请输入专业代号${index + 1}`" v-model="editMajor[index].major_id">
                </el-input>
                <el-input style="width: 49%" :placeholder="`请输入专业名称${index + 1}`" v-model="editMajor[index].major_CN_name">
                  <template slot="append">
                    <i class="el-icon-delete" style="color: red" @click="handleDeleteEditMajor(index)"></i>
                  </template>
                </el-input>
              </div>
            </el-form-item>
          </el-form-item>
          <el-form-item style="margin-top: 20px;">
            <el-button type="primary" @click="EditCollegeAndMajor"  style="background-color: #71246B">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      addCollegeFormVisible:false,
      editCollegeFormVisible:false,//编辑
      collegeList:[],
      major:[],
      addCollege: {
        college_id:'',
        college_name:''
      },
      addMajor:[{}],
      originCollege:{
        college_id:'',
        college_name:''
      },
      editCollege:{
        college_id:'',
        college_name:''
      },
      editMajor:[{}, {},{}],
      tableHeight:null,
    }
  },
  created() {
    this.$http.get("/getCollegeAndMajorList")
        .then(response=>{
          this.collegeList = response.data.data.collegeList;
          this.major = response.data.data.majorList;
        })
  },
  computed: {
    processedCollege() {
      return this.collegeList.map(req => {
        const major = this.major.filter(detail => detail.college_id === req.college_id);
        return { ...req, major };
      });
    }
  },
  mounted() {
    this.tableHeight = window.innerHeight - 150;
  },
  methods:{
    refresh() {
      this.$http.get("/getCollegeAndMajorList")
          .then(response => {
            this.collegeList = response.data.data.collegeList;
            this.major = response.data.data.majorList;
          })
          .catch(error => {
            this.$message.error("数据刷新失败！");
          });
    },
    rowClassName({ row, rowIndex }) {
      return `row-${rowIndex}`;
    },
    AddCollegeAndMajor(){
      this.$http.post('/addCollegeAndMajor',{college:this.addCollege,major:this.addMajor})
          .then(response=>{
            this.$message.success('提交成功！')
            this.refresh()
            this.addCollege=''
            this.addMajor=['']
          }).catch('提交失败')
      this.addCollegeFormVisible=false;
    },
    EditCollegeAndMajor(){
      this.$http.post('/editCollegeAndMajor',{originCollege:this.originCollege,editCollege:this.editCollege,editMajor:this.editMajor})
          .then(response=>{
            this.$message.success('提交成功！')
            this.refresh();
            this.editCollege=''
            this.editMajor=['','','']
          }).catch(response=>{
            this.$message.error("提交失败！")
      })
      this.editCollegeFormVisible=false
      this.refresh()
    },
    handleAdd(){
      this.addCollegeFormVisible = true;
    },
    handleAddAddMajor(){
      this.addMajor.push('');
    },
    handleAddEditMajor(){
      this.editMajor.push('');
    },
    handleDeleteAddMajor(index) {
      this.addMajor.splice(index, 1);
    },
    handleDeleteEditMajor(index) {
      this.editMajor.splice(index, 1);
    },
    handleUpdate(scope) {
      // 编辑学院与专业的逻辑
      const college = scope.row;
      this.editCollege = { ...college };
      this.originCollege = { ...college };
      this.editMajor = college.major.map(m => ({ ...m }));
      this.editCollegeFormVisible = true;
    },
    handleDelete(scope) {
      // 删除学院与专业的逻辑
      const college = scope.row;
      this.$confirm(`此操作将永久删除 ${college.college_name} 及其开设的专业, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/deleteCollegeAndMajor', { college_id: college.college_id })
            .then(response => {
              this.$message.success('删除成功！');
              this.refresh();
            }).catch(() => {
          this.$message.error(response=>{
            this.$message.error("删除失败")
          });
        });
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
  }
}
</script>

<style>
.task-list-container {
  width: 100%; /* 设定清单容器的固定宽度 */
  height: 40vh; /* 设定清单容器的固定高度 */
  overflow-y: scroll; /* 使清单内容超出容器高度时可滚动 */
  border: 1px solid #ccc;
}

.task-list {
  list-style-type: none; /* 去除列表默认样式 */
  padding: 0; /* 去除列表的内边距 */
  margin: 0; /* 去除列表的外边距 */
}

.task-item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
