import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

// 定义存储引擎
const localStorage = window.localStorage;

export default new Vuex.Store({
    plugins: [createPersistedState({ storage: localStorage })], // 使用插件实现状态持久化
    state: {
        loginUser: {
            employee_id: '',
            major_id: '',
            college_id: '',
        },
        enrollment_year: '',
        // notification: {
        //     content: '',
        //     release_time: '',
        // },

    },
});
