<template>
  <div id="home">
    <div style="display: flex;flex-direction: column;width: 100%;height: 100%">
      <div style="display: flex;flex-direction: row;background-color: #71246B;width: 100%;margin-top: 0">
        <div style="margin-left: 1%">
          <img src="@/assets/picture/tg_logo_p.png" alt="" style="height: 50px;margin:4%">
        </div>
        <div style="display: flex;justify-content: center;align-items: center;width: 20%;color:white;font-size: 16px">
          欢迎使用培养方案修订系统！
        </div>
      </div>
      <div style="width: 100%;height: 100%;display: flex; align-items: center;justify-content: center;flex-direction: column">
        <div class="login">

          <div class="left">
            <div class="text">
              <h3 style="color: white;text-align: left;margin-top: 0;margin-bottom: 0;font-size: 16px">通知公告</h3>
              <hr style="background-color: white; width: 100%; height: 2px;">
              <div style="color: white;font-size: 15px;text-align: left">{{notification.content}}</div>
              <div style="margin-top: 45%">
                <div style="color: white;font-size: 15px;text-align: right;">教务处</div>
                <div style="color: white;font-size: 15px;text-align: right;">{{notification.release_time}}</div>
              </div>

            </div>
          </div>

          <div class="right1">
            <div style="display: flex; justify-content: center;align-items: center">
              <img src="@/assets/picture/tg_logo.png" alt="天工大logo蒙版" style="width: 70%;display: block; margin: 5%;">
            </div>
            <hr style="background-color: #cccccc; width: 100%; height: 1px;">
            <div class="idAndPassword">
              <el-form >
                <el-form-item prop="employee_id">
                  <el-input class="custom_input" v-model="loginUser.employee_id" prefix-icon="el-icon-user" placeholder="请输入工号"></el-input>
                </el-form-item>
                <el-form-item prop="user_password">
                  <el-input :type="type" class="custom_input" v-model="loginUser.user_password" prefix-icon="el-icon-lock" placeholder="请输入密码"><i slot="suffix" class="icon-style" :class="elIcon" @click="flag = !flag"/></el-input>
                </el-form-item>
                <el-form-item prop="verification_code">
                  <el-form style="display: flex; align-items: center;">
                    <el-input class="custom_input" prefix-icon="el-icon-key" v-model="code" placeholder="验证码" style="flex: 1; margin-right: 10px;"></el-input>
                    <div>
                      <img :src="captchaUrl" alt="验证码" @click="refreshCaptcha" style="cursor: pointer; width: 80px; height: 40px;margin-bottom: -10px">
                    </div>
                  </el-form>
                </el-form-item>
              </el-form>
              <div style="width: 100%">
                <el-button @click.prevent="loginMethod" style="border-radius: 10px; background-color: #71246B; color: white; width: 100%">登录</el-button>
                <router-link to="/password" style="font-size: 10px; text-decoration: none; color: #808080;">忘记密码?</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div style="color:black;text-align: left">本系统由大数据2201张帅、宋源博、鲁宏昆开发，指导教师：荣垂田。</div>-->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data(){
    return {
      flag:false,
      captchaUrl: '',
      codeId:'',
      code:'',
      loginUser:{
        employee_id: null,
        user_password: null,
        user_name: null,
        role_: null,
        email: null,
        major_id: null,
        college_id: null
      },
      notification:{
        content:'',
        release_time: '',
      }
    }
  },
  created() {
    this.$http.get('/getNotification').then((response)=>{
      this.notification.release_time=response.data.data.release_time;
      this.notification.content=response.data.data.content;
    })
  },
  methods: {
    refreshCaptcha() {
      // 调用后端接口获取验证码
      this.$http.get('/captcha', {
        responseType: 'arraybuffer'  // 告诉axios返回的数据为二进制数据
      }).then(response => {
        // 从响应头获取codeId
        this.codeId = response.headers.get('codeId');

        // 将返回的二进制数据转为base64格式
        const imageData = btoa(
            new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this.captchaUrl = 'data:image/jpeg;base64,' + imageData;

      }).catch(error => {
        this.$message.error('获取验证码失败');
      });
    },
    loginMethod() {
      this.$http.post("/login", {employee_id:this.loginUser.employee_id,user_password:this.loginUser.user_password,codeId:this.codeId,code:this.code})
          .then(response => {
            let token = response.data.data.token;
            if (token) {
              console.log(response.data.data);
              this.$store.state.enrollment_year = new Date().getFullYear();
              localStorage.setItem('enrollment_year',this.$store.state.enrollment_year)
              if (response.data.data.role_ === 1) {
                this.$router.push("/school-admin/menu");
                this.$message.success("登录成功");
                localStorage.setItem('token', response.data.data.token);
                localStorage.setItem('employee_id',this.loginUser.employee_id)
                // this.$store.state.loginUser.employee_id = response.data.data.employee_id;
              } else if (response.data.data.role_=== 2) {
                this.$router.push("/college-admin/menu");
                this.$message.success("登录成功");
                localStorage.setItem('token', response.data.data.token);
                // this.$store.state.loginUser.employee_id = response.data.data.employee_id;
                // this.$store.state.loginUser.college_id = response.data.data.college_id;
                localStorage.setItem('employee_id',this.loginUser.employee_id)
                localStorage.setItem('college_id',response.data.data.college_id)
              } else {
                this.$router.push("/major-admin/menu");
                this.$message.success("登录成功");
                localStorage.setItem('token', response.data.data.token);
                // this.$store.state.loginUser.employee_id = this.loginUser.employee_id;
                // this.$store.state.loginUser.college_id = this.loginUser.college_id;
                // this.$store.state.loginUser.major_id = this.loginUser.major_id
                localStorage.setItem('college_id',response.data.data.college_id)
                localStorage.setItem('employee_id',this.loginUser.employee_id)
                localStorage.setItem('major_id',response.data.data.major_id)
              }
              axios.defaults.headers.common['X-TOKEN'] = localStorage.getItem('token'); // 设置请求头中的 X-TOKEN 字段
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch(error => {
            this.$message.error('登录失败!');
          });
    },
    handleKeyDown(event) {
      if (event.keyCode === 13) {
        event.preventDefault(); // 阻止默认提交行为
        this.loginMethod();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  mounted() {
    this.refreshCaptcha(); // 页面挂载后再获取验证码图片
    document.addEventListener('keydown', this.handleKeyDown);
  },
  computed: {
    type() {
      return this.flag ? "text" : "password";
    },
    elIcon() {
      return this.flag ? "el-icon-view" : "iconfont icon-biyan";
    }
  }
}
</script>

<style>
#home {
  width: 100%;
  height: 100vh;
  background: url("@/assets/picture/login.jpg") center center no-repeat;
  background-size: 100% 100%;
  position: absolute;
}
.login {
  background-color: white;
  width: 50%;
  height: 60%;
  display:flex;
  flex-direction:row;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 添加阴影 */
  border-radius: 10px; /* 添加圆角 */
}
.left{
  background-color: #71246B;
  width: 58%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 10px 0 0 10px; /* 添加圆角 */
  justify-content: center;
  align-items: center;
}
.text{
  width: 80%;
  height:70%;
  display: flex;
  flex-direction: column;
}
.right1{
  border-radius: 0 10px 10px 0; /* 添加圆角 */
  width: 42%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.idAndPassword{
  height:100%;
  width: 80%;
  margin-left: 10%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.custom_input input{
  border-color: #8F8F8F;
  border-radius: 10px;
}
</style>
