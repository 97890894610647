<template>
  <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
    <h4>指导性教学计划</h4>
    <div>
      <vue-office-docx
          :src="docx"
          style="border: 1px solid #ccc;height: calc(100vh - 300px)"
          @rendered="renderedHandler"
          @error="errorHandler"
      />
    </div>
    <div style="width: 95%; display: flex; flex-direction: column; justify-content: left; align-items: flex-start">
      <h6 style="margin:10px">一、此表由课程数据自动汇总，无法修改；如想修改，请前往课程板块修改</h6>
    </div>
    <div style="margin-top: 10px; margin-bottom: 10px; display: flex; justify-content: flex-end; width: 95%">
      <el-button type="primary" @click="handleSubmit()" style="background-color: #71246B;">确认</el-button>
    </div>
  </div>
</template>

<script>
//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
//引入相关样式
import '@vue-office/docx/lib/index.css'
export default {
  components: {
    VueOfficeDocx
  },
  data() {
    return {
      docx: '' //设置文档网络地址，可以是相对地址
    }
  },
  created() {
    this.$http.post("/previewGuidingTeachingPlan",{major_id:localStorage.getItem('major_id'),enrollment_year:localStorage.getItem('enrollment_year')})
        .then(response=>{
          this.docx = response.data.data.target
        })
  },
  methods: {
    renderedHandler() {
      console.log("渲染完成")
    },
    errorHandler() {
      console.log("渲染失败")
    },
    handleSubmit() {
      this.$http.post('/finishTable6',{major_id:localStorage.getItem('major_id')})
          .then(response=>{
            this.$message.success("保存成功！");
          })
          .catch(error=>{
            this.$message.error("保存失败！")
          })
    }
  }
}
</script>
