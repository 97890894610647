<template>
  <div style="overflow: auto">
    <div style="margin: 1%;display: flex;flex-direction: column;">
      <div style="display: flex;flex-direction: row;justify-content: left">
        <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i><div style="font-size: 12px;color:grey;margin-top: -5px">培养方案</div>
        <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i><div style="font-size: 12px;color:grey;margin-top: -5px">其他</div>
      </div>
      <div style="background-color: white;margin-top: 5px;display: flex;flex-direction: column;justify-content: left;">
        <div style="display: flex;flex-direction: row">
          <div style="display: flex;flex-direction: column;align-items: center;justify-content: left;width: 48%;margin-right: 1%">
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: left;width: 100%">
              <h4 style="width: 28.3%;display: flex;justify-content: right">1.主干学科：</h4>
              <el-input style="width: 72%;color: #5A5E66" v-model="majorInfo.core_subject"></el-input>
            </div>
            <div style="display: flex;flex-direction: row;align-items: flex-start;justify-content: left;margin-top: -10px;width: 100%">
              <h4 style="width: 30%;display: flex;justify-content: right;border-radius: 4px;">2.核心知识领域：</h4>
              <textarea style="margin-top: 16px;color: #5A5E66; height: 130px;width: 70%;border-radius: 4px; background-color: #FFF; border: 1px solid #DCDFE6;resize: none;font-size: 16px;line-height: 2;padding: 0 15px;outline-color: rgba(64,158,255);" v-model="majorInfo.core_knowledge_areas"></textarea>
            </div>
            <div style="display: flex;flex-direction: row;align-items: flex-start;justify-content: left;width: 100%">
              <h4 style="width: 30%;display: flex;justify-content: right;border-radius: 4px;">3.专业核心课程：</h4>
              <textarea v-model="majorInfo.major_core_courses" style="margin-top: 16px;color: #5A5E66; height: 240px;width: 70%;border-radius: 4px; background-color: #FFF; border: 1px solid #DCDFE6;resize: none;font-size: 16px;line-height: 2;padding: 0 15px;outline-color: rgba(64,158,255);"></textarea>
            </div>
          </div>
          <div style="display: flex;flex-direction: column;align-items: center;justify-content: left;width: 48%;margin-left: 1%">
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: left;width: 100%">
              <h4 style="width: 28.3%;display: flex;justify-content: right">4.修业年限：</h4>
              <el-input v-model="majorInfo.study_duration" style="width: 72%;color: #5A5E66"></el-input>
            </div>
            <div style="display: flex;flex-direction: row;align-items: flex-start;justify-content: left;margin-top: -10px;width: 100%">
              <h4 style="width: 30%;display: flex;justify-content: right;border-radius: 4px;">5.主要实践教学：</h4>
              <textarea v-model="majorInfo.core_practical_teaching" style="margin-top: 16px;color: #5A5E66; height: 130px;width: 70%;border-radius: 4px; background-color: #FFF; border: 1px solid #DCDFE6;resize: none;font-size: 16px;line-height: 2;padding: 0 15px;outline-color: rgba(64,158,255);"></textarea>
            </div>
            <div style="display: flex;flex-direction: row;align-items: flex-start;justify-content: left;width: 100%">
              <h4 style="width: 30%;display: flex;justify-content: right;border-radius: 4px;" >6.主要专业实验：</h4>
              <textarea v-model="majorInfo.core_experiments" style="margin-top: 16px;color: #5A5E66; height: 185px;width: 70%;border-radius: 4px; background-color: #FFF; border: 1px solid #DCDFE6;resize: none;font-size: 16px;line-height: 2;padding: 0 15px;outline-color: rgba(64,158,255);"></textarea>
            </div>
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: left;width: 100%">
              <h4 style="width: 28.3%;display: flex;justify-content: right">7.授予学位：</h4>
              <el-input v-model="majorInfo.awarded_degree" style="width: 72%;color: #5A5E66!important;"></el-input>
            </div>
          </div>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: right">
          <el-button type="primary" @click="submitOthers" style="width:8%;margin-right:2%;background-color: #71246B">保存</el-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return{
      majorInfo:{
        major_id:localStorage.getItem('major_id'),
        enrollment_year:localStorage.getItem('enrollment_year'),
        core_subject:'',
        core_knowledge_areas:'',
        major_core_courses:'',
        core_practical_teaching:'',
        core_experiments:'',
        study_duration:'',
        awarded_degree:''
      }
    }

  },
  created() {
    this.$http.post('/getOthers',{major_id:this.majorInfo.major_id,enrollment_year:this.majorInfo.enrollment_year})
        .then(response=>{
          this.majorInfo = response.data.data;
        }).catch('获取专业信息失败！')
  },
  methods: {
    submitOthers(){
      this.$http.post('/finishOthers',{major_id:localStorage.getItem('major_id')})
      this.$http.post('/submitOthers',{majorInfo:this.majorInfo})
          .then(response=>{
            this.$message.success('保存成功!')
          }).catch('保存失败！')
    }
  }
}
</script>

<style>
.el-input__inner{
  color: #5A5E66 !important;
}
</style>
