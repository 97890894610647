<template>
  <div style="width: 100%;display: flex;flex-direction: column; justify-content: center;align-items: center">
    <h4>专业实习、课程设计或其他实践</h4>
    <el-table
        ref="tableData"
        :data="practices"
        border
        show-summary
        :summary-method="getSummaries"
        stripe
        :min-height="tableHeight"
        :max-height="tableHeight"
        style="width: 951px;">
      <el-table-column
          prop="practice_id"
          label="课号"
          width="150">
      </el-table-column>
      <el-table-column
          fixed
          prop="practice_name"
          label="名称"
          width="200">
      </el-table-column>
      <el-table-column
          prop="semester"
          label="学期"
          width="50">
      </el-table-column>
      <el-table-column
          prop="weeks"
          label="周数"
          width="50">
      </el-table-column>
      <el-table-column
          prop="credit"
          label="学分"
          width="50">
      </el-table-column>
      <el-table-column
          prop="comments"
          label="备注"
          width="300">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="150">
        <template slot-scope="scope">
          <el-button @click="handleUpdate(scope)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
          <el-button @click="handleDelete(scope)" type="text" size="small" icon="el-icon-delete" style="color: red">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px;margin-bottom: 10px;display: flex;justify-content: flex-end;width: 951px">
      <el-button type="primary" @click="handleAddPractice" style="height: 80%;background-color: #71246B">新增</el-button>
      <el-button type="primary" @click="submitPractices" style="height: 80%;background-color: #71246B">保存</el-button>
    </div>
    <el-dialog title="新增实践" :visible.sync="addFormVisible" :modal="true" :modal-append-to-body="false">
      <el-form :inline="true">
        <el-form-item label="课号" style="margin-right: 30px">
          <el-input placeholder="请输入课号" v-model="addPractice.practice_id"></el-input>
        </el-form-item>
        <el-form-item label="名称" style="margin-left: 30px">
          <el-input placeholder="请输入名称" v-model="addPractice.practice_name"></el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="学期" style="margin-right: 30px">
          <el-input placeholder="请输入学期" v-model="addPractice.semester"></el-input>
        </el-form-item>
        <el-form-item label="周数" style="margin-left: 30px">
          <el-input placeholder="请输入周数" v-model="addPractice.weeks"></el-input>
        </el-form-item>
      </el-form>
      <el-form>
        <el-form :inline="true">
          <el-form-item label="学分" style="margin-right: 30px">
            <el-input placeholder="请输入学分" v-model="addPractice.credit"></el-input>
          </el-form-item>
          <el-form-item label="备注" style="margin-left: 30px">
            <el-input placeholder="请输入备注" v-model="addPractice.comments"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="submitAddPractice" style="background-color: #71246B">保存</el-button>
      </el-form>
    </el-dialog>
    <el-dialog title="编辑实践" :visible.sync="editFormVisible" :modal="true" :modal-append-to-body="false">
      <el-form :inline="true">
        <el-form-item label="课号" style="margin-right: 30px">
          <el-input placeholder="请输入课号" v-model="editPractice.practice_id"></el-input>
        </el-form-item>
        <el-form-item label="名称" style="margin-left: 30px">
          <el-input placeholder="请输入名称" v-model="editPractice.practice_name"></el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="学期" style="margin-right: 30px">
          <el-input placeholder="请输入学期" v-model="editPractice.semester"></el-input>
        </el-form-item>
        <el-form-item label="周数" style="margin-left: 30px">
          <el-input placeholder="请输入周数" v-model="editPractice.weeks"></el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="学分" style="margin-right: 30px">
          <el-input placeholder="请输入学分" v-model="editPractice.credit"></el-input>
        </el-form-item>
        <el-form-item label="备注" style="margin-left: 30px">
          <el-input placeholder="请输入备注" v-model="editPractice.comments"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submitEditPractice" style="background-color: #71246B">保存</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addPractice: {
        major_id: localStorage.getItem('major_id'),
        enrollment_year: localStorage.getItem('enrollment_year'),
        practice_name: '',
        practice_id: '',
        credit: '',
        weeks: '',
        comments: '',
        semester: ''
      },
      oldPracticeId: '',
      editPractice: {
        major_id: localStorage.getItem('major_id'),
        enrollment_year: localStorage.getItem('enrollment_year'),
        practice_name: '',
        practice_id: '',
        credit: '',
        weeks: '',
        comments: '',
        semester: ''
      },
      practices: [],
      addFormVisible: false,
      editFormVisible: false,
      tableHeight:null
    };
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.tableData) {
        this.$refs.tableData.doLayout();
      }
    });
  },
  mounted() {
    this.tableHeight = window.innerHeight - 250;
  },
  created() {
    this.$http.post('/table5/getPractices', {major_id: localStorage.getItem('major_id'), enrollment_year: localStorage.getItem('enrollment_year')})
        .then(response => {
          this.practices = response.data.data.practices;
        })
        .catch(() => {
          this.$message.error('获取实践列表失败！');
        });
  },
  methods: {
    refresh() {
      this.$http.post('/table5/getPractices', {
        major_id: localStorage.getItem('major_id'),
        enrollment_year: localStorage.getItem('enrollment_year')
      })
          .then(response => {
            this.practices = response.data.data.practices;
          })
          .catch(() => {
            this.$message.error('获取实践列表失败！');
          });
    },
    handleAddPractice() {
      this.addFormVisible = true;
    },
    submitAddPractice() {
      this.$http.post("/table5/submitAddPractice", {Practice: this.addPractice})
          .then(response => {
            this.addFormVisible = false;
            this.$message.success('保存成功！');
            this.addPractice = {
              major_id: localStorage.getItem('major_id'),
              enrollment_year: localStorage.getItem('enrollment_year'),
              practice_name: '',
              practice_id: '',
              credit: '',
              weeks: '',
              comments: '',
              semester: ''
            };
            this.refresh();
          })
          .catch(() => {
            this.$message.error('保存失败！');
          });
    },
    submitPractices() {
      this.$http.post('/finishTable3',{major_id:localStorage.getItem('major_id')})
          .then(response=>{
            this.$message.success("保存成功！");
          })
          .catch(error=>{
            this.$message.error("保存失败！")
          })
    },
    handleUpdate(scope) {
      this.editFormVisible = true;
      this.editPractice = { ...scope.row };
      this.oldPracticeId = scope.row.practice_id;
    },
    submitEditPractice() {
      if (this.editPractice.practice_id !== this.oldPracticeId) {
        this.$http.post("/table5/editPracticeAndPracticeId", {
          oldPracticeId: this.oldPracticeId,
          practice: this.editPractice
        })
            .then(response => {
              this.editFormVisible = false;
              this.$message.success('实践编辑成功！');
              this.refresh();
            })
            .catch(() => {
              this.$message.error('实践编辑失败！');
            });
      } else {
        this.$http.post("/table5/editPractice", this.editPractice)
            .then(response => {
              this.editFormVisible = false;
              this.$message.success('实践编辑成功！');
              this.refresh();
            })
            .catch(() => {
              this.$message.error('实践编辑失败！');
            });
      }
    },
    handleDelete(scope) {
      this.$confirm('此操作将永久删除该实践信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/table5/deletePractice", {practice_id: scope.row.practice_id})
            .then(response => {
              this.$message.success('删除成功！');
              this.refresh();
            })
            .catch(() => {
              this.$message.error('删除失败！');
            });
      });
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property === 'credit') {
          const values = data.map(item => Number(item[column.property]));
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });
      return sums;
    }
  }
}
</script>

<style>
.el-table {
  overflow: visible !important;
}
</style>
