<template>
  <div style="width: 100%;display: flex;flex-direction: column; justify-content: center;align-items: center">
    <h4>毕业要求对培养目标的支撑关系</h4>
    <el-table :data="requirementsList"
              :min-height="tableHeight"
              :max-height="tableHeight"
              style="width: 80%"
              border>
      <el-table-column label="要求/培养目标">
        <template slot-scope="scope">
          <span>{{ '要求' + (scope.$index + 1) }}</span>
        </template>
      </el-table-column>
      <el-table-column v-for="(objective, index) in training_objectiveList" :key="objective.id" :label="'培养目标' + (index + 1)">
        <template slot-scope="scope">
          <el-checkbox :checked="scope.row.supports.includes(objective.id)" @change="updateSupports(scope.row, objective.id)"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px;margin-bottom: 10px;display: flex;justify-content: flex-end;width: 95%">
      <el-button type="primary" @click="handleSubmit()"  style="background-color: #71246B;">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      requirementsList: [],
      training_objectiveList: [],
      tableHeight:null,
    };
  },
  mounted() {
    console.log(window.innerHeight)
    this.tableHeight = window.innerHeight - 300;
    // 后面的50：根据需求空出的高度，自行调整
  },
  created() {
    this.$http.post('/table1/getRequirementsList', { major_id: localStorage.getItem('major_id'), enrollment_year: localStorage.getItem('enrollment_year') })
        .then(response => {
          this.requirementsList = response.data.data.requirements.map(requirement => {
            return {
              ...requirement,
              supports: requirement.supports ? requirement.supports.split(',').map(Number) : []
            };
          });
          console.log(this.requirementsList);
        })
        .catch(error => {
          this.$message.error("获取失败");
        });
    this.$http.post('/table1/getTraining_objectiveList', { major_id: localStorage.getItem('major_id'), enrollment_year: localStorage.getItem('enrollment_year') })
        .then(response => {
          this.training_objectiveList = response.data.data.trainingObjectiveList;
        })
        .catch(error => {
          this.$message.error("获取失败");
        });
  },
  methods: {
    updateSupports(requirement, objectiveId) {
      const id = Number(objectiveId);
      if (requirement.supports.includes(id)) {
        requirement.supports = requirement.supports.filter(supportId => supportId !== id);
      } else {
        requirement.supports.push(id);
      }
    },
    handleSubmit() {
      const formattedRequirements = this.requirementsList.map(requirement => {
        return {
          ...requirement,
          supports: requirement.supports.join(',')
        };
      });

      this.$http.post('/table1/submitTable1', {major_id:localStorage.getItem('major_id'), requirements: formattedRequirements })
          .then(response => {
            this.$message.success('保存成功!');
          })
          .catch(error => {
            this.$message.error('保存失败!');
          });
      this.$http.post('/finishSupports1',{major_id:localStorage.getItem('major_id')})
    }
  }
};
</script>
