<template>
  <div style="margin: 1%;display: flex;flex-direction: column">
    <div style="display: flex;flex-direction: row;justify-content: left">
      <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i><div style="font-size: 12px;color:grey;margin-top: -5px">首页</div>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 5px">
      <div style="display: flex;flex-direction: column;background-color: white;width: 44%;height: 25vh">
        <h3 style="text-align: left;color: #2D2F33;margin-left:15px;margin-top:15px;font-size: 16px">专业介绍</h3>
        <hr style="background-color: #808080; width: 95%;margin-top:-5px;">
        <div style="text-align: left;margin-left:15px;margin-top:5px;font-size: 16px"><b>专业名:  </b><span style="color:#5A5E66;">{{ majorInfo.major_CN_name }}</span></div>
        <div style="text-align: left;margin-left:15px;margin-top:15px;font-size: 16px"><b>专业代号: </b><span style="color:#5A5E66;">{{ majorInfo.major_id }}</span></div>
        <div style="text-align: left;margin-left:15px;margin-top:15px;margin-bottom: 15px;font-size: 16px"><b>所属学院: </b><span style="color:#5A5E66;">{{ college_name }}</span></div>
      </div>
      <div style="display: flex;flex-direction: column;justify-content: space-between; background-color: white;width: 55%;height: 25vh;margin-left: 1%">
        <div><h3 style="text-align: left;color: #2D2F33;margin-left:15px;margin-top:15px;font-size: 16px">通知</h3>
          <hr style="background-color: #808080; width: 95%;margin-top:-5px;">
          <div style="color: #5A5E66;font-size: 15px;text-align: left;margin-left:15px;">{{this.notification.content}}</div>
        </div>
        <div>
        <div style="color: #5A5E66;font-size: 15px;text-align: right;margin-right:15px;">教务处</div>
        <div style="color: #5A5E66;font-size: 15px;text-align: right;margin-right:15px;">{{this.notification.release_time}}</div>
        </div>
      </div>
    </div>
    <div style="display: flex;flex-direction: column;background-color: white;margin-top: 1%">
      <h3 style="text-align: left; color: #2D2F33;margin-left:15px;margin-top:15px;font-size: 16px">任务清单</h3>
      <div class="task-list-container">
        <ul class="task-list">
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">培养目标</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_training_objective == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_training_objective == 3"><router-link to="/major-admin/trainingObjective" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/trainingObjective" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">毕业要求</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_requirements == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_requirements == 3"><router-link to="/major-admin/requirements" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/requirements" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">毕业要求对培养目标的支撑</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_supports1 == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_supports1 == 3"><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table1')">被打回</a></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table1')">去完成
            </a></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">主干学科</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_core_subject == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_core_subject == 3"><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">核心知识领域</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_core_knowledge_areas == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_core_knowledge_areas == 3"><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">专业核心课程</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_major_core_courses == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_major_core_courses == 3"><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">主要实践性教学环节</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_core_practical_teaching == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_core_practical_teaching == 3"><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">主要专业实验</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_core_experiments == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_core_experiments == 3"><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">修业年限</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_study_duration == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_study_duration == 3"><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">授予学位</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_awarded_degree == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_awarded_degree == 3"><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/others" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">课程录入</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_courses == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_courses == 3"><router-link to="/major-admin/course" style="color: blue;text-decoration: none;">被打回</router-link></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><router-link to="/major-admin/course" style="color: blue;text-decoration: none;">去完成</router-link></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">课程体系对毕业要求的支撑关系</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_supports2 == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_supports2 == 3"><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table2')">被打回</a></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table2')">去完成
            </a></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">学历表</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_table1 == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_table1 == 3">
              <a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table3')">被打回</a></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else>
              <a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table3')">去完成
              </a>
            </i>

          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">各必修、限选课程学时、学分分配统计表</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_table2 == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_table2 == 3"><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table4')">被打回</a></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table4')">去完成
            </a></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">专业实习、课程设计或其他实践</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_table3 == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_table3 == 3"><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table5')">被打回</a></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table5')">去完成
            </a></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">学生应修各类课程学分统计表</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_table4 == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_table4 == 3"><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table6')">被打回</a></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table6')">去完成
            </a></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">时间分配（以周记）</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_table5 == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_table5 == 3"><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table7')">被打回</a></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table7')">去完成
            </a></i>
          </li>
          <li class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">指导性教学计划</div>
            <i class="iconfont icon-yiwancheng" v-if="user_state.submit_table6 == 1">已完成</i>
            <i class="el-icon-warning" style="color: red" v-else-if="user_state.submit_table6 == 3"><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table8')">被打回</a></i>
            <i style="color: blue" class="iconfont icon-weiwancheng" v-else><a href="#/major-admin/table" style="color: blue; text-decoration: none;" @click="setLocalStorage('/major-admin/table/table8')">去完成
            </a></i>
          </li>
        </ul>
      </div>
      <div style="display: flex;flex-direction: row;justify-content: right;margin-top: 10px;margin-bottom: 10px" >
        <el-button type="info" round style="width: 8%" v-if="user_state.is_submit == 1" @click="showHadSubmitMessage">已提交</el-button>
        <el-button type="success"  @click="handleSubmit" round style="width: 8%;background-color: #71246B" v-else-if="user_state.submit_table6 == 1 && user_state.submit_training_objective == 1 && user_state.submit_requirements == 1 && user_state.submit_supports1 == 1 && user_state.submit_core_subject == 1 && user_state.submit_core_knowledge_areas == 1 && user_state.submit_major_core_courses == 1 && user_state.submit_core_practical_teaching == 1 && user_state.submit_core_experiments== 1 && user_state.submit_study_duration == 1 && user_state.submit_awarded_degree == 1 && user_state.submit_supports2 == 1 && user_state.submit_table1 == 1 && user_state.submit_table2 == 1 && user_state.submit_table3 == 1 && user_state.submit_table4 == 1 && user_state.submit_table5 == 1 ">提交</el-button>
        <el-button type="success"  @click="handleSubmit" round style="width: 8%;background-color: #71246B" v-else-if="user_state.submit_table6 == 3 || user_state.submit_training_objective == 3 || user_state.submit_requirements == 3 || user_state.submit_supports1 == 3 || user_state.submit_core_subject == 3 || user_state.submit_core_knowledge_areas == 3 || user_state.submit_major_core_courses == 3 || user_state.submit_core_practical_teaching == 3 || user_state.submit_core_experiments== 3 || user_state.submit_study_duration == 3 || user_state.submit_awarded_degree == 3 || user_state.submit_supports2 == 3 || user_state.submit_table3 == 3 || user_state.submit_table2 == 3 || user_state.submit_table3 == 3 || user_state.submit_table4 == 3 || user_state.submit_table5 == 3 ">重新提交</el-button>
        <el-button type="info" round style="width: 8%" v-else @click="showErrorMessage">无法提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      enrollment_year: localStorage.getItem('enrollment_year'),
      majorInfo:{
        major_id:localStorage.getItem('major_id'),
        major_CN_name:'',
      },
      college_name: '',
      notification:{
        content:'',
        release_time: '',
      },
      user_state: {
        employee_id: localStorage.getItem('employee_id'),
        is_submit: '',
        is_checked_by_college: '',
        is_checked_by_school:'',
        enrollment_year: '',
        submit_training_objective: '',
        submit_requirements: '',
        submit_supports1: '',
        submit_core_subject: '',
        submit_core_knowledge_areas: '',
        submit_major_core_courses: '',
        submit_core_practical_teaching: '',
        submit_core_experiments: '',
        submit_study_duration: '',
        submit_awarded_degree: '',
        submit_courses: '',
        submit_supports2: '',
        submit_table1: '',
        submit_table2: '',
        submit_table3: '',
        submit_table4: '',
        submit_table5: '',
        submit_table6:'',
        warning: '',
      },
    }
  },
  created() {
    this.$http.post("/getUserState",{employee_id:localStorage.getItem('employee_id')}).then(response=>{
      this.user_state = response.data.data;
    })
    this.$http.get('/getNotification').then((response)=>{
      this.notification.release_time=response.data.data.release_time;
      this.notification.content=response.data.data.content;
    })
    this.$http.post('/getMajorInfo',{major_id:this.majorInfo.major_id}).then(response=>{
      this.majorInfo.major_CN_name = response.data.data.major_CN_name;
      this.college_name=response.data.data.college_name;
    })
  },
  methods: {
    showHadSubmitMessage(){
      this.$message.error('无法重复提交！');
    },
    setLocalStorage(value) {
      localStorage.setItem('flag', value);
    },
    showErrorMessage() {
      this.$message.error('请完成所有任务后提交!');
    },
    handleSubmit(){
      this.$http.post('/finishAll',{major_id:localStorage.getItem('major_id'),enrollment_year:localStorage.getItem('enrollment_year')})
          .then(response=>{
            this.$message.success("提交成功！");
          })
          .catch(error=>{
            this.$message.error("提交失败！")
          })
    }
  }
}
</script>

<style>
.task-list-container {
  width: 100%; /* 设定清单容器的固定宽度 */
  height: 40vh; /* 设定清单容器的固定高度 */
  overflow-y: scroll; /* 使清单内容超出容器高度时可滚动 */
  border: 1px solid #ccc;
  font-size: 16px
}

.task-list {
  list-style-type: none; /* 去除列表默认样式 */
  padding: 0; /* 去除列表的内边距 */
  margin: 0; /* 去除列表的外边距 */
}

.task-item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
