<template>
  <div style="margin: 1%; display: flex; flex-direction: column">
    <div style="display: flex; flex-direction: row; justify-content: left">
      <i class="iconfont icon-normal" style="font-size: 12px; margin-top: -3px; color: grey"></i>
      <div style="font-size: 12px; color: grey; margin-top: -5px">首页</div>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 5px">
      <div style="display: flex; flex-direction: column; background-color: white; width: 44%; height: 25vh">
        <h3 style="text-align: left; color: #2D2F33; margin-left: 15px; margin-top: 15px;font-size: 16px">学院介绍</h3>
        <hr style="background-color: #808080; width: 95%; margin-top: -5px;">
        <div style="text-align: left; margin-left: 15px; margin-top: 5px;font-size: 16px"><b>学院名:  </b><span style="color: #5A5E66;">{{ college.college_name }}</span></div>
        <div style="flex-direction: row; display: flex">
          <div style="text-align: left; margin-left: 15px; margin-top: 15px;font-size: 16px"><b>下设专业：</b></div>
          <textarea v-model="majorNames" style="width: 80%; height: 70px; margin-top: 15px; resize: none; border: 0; outline: none; font-size: 16px; line-height: 1.5; color: rgb(90, 94, 102); font-family: Avenir, Helvetica, Arial, sans-serif;" readonly></textarea>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: space-between; background-color: white; width: 55%; height: 25vh; margin-left: 1%">
        <div>
          <h3 style="text-align: left; color: #2D2F33; margin-left: 15px; margin-top: 15px;font-size: 16px">通知</h3>
          <hr style="background-color: #808080; width: 95%; margin-top: -5px;">
          <div style="color: #5A5E66; font-size: 15px; text-align: left; margin-left: 15px;">{{ notification.content }}</div>
        </div>
        <div>
          <div style="color: #5A5E66; font-size: 15px; text-align: right; margin-right: 15px;">教务处</div>
          <div style="color: #5A5E66; font-size: 15px; text-align: right; margin-right: 15px;">{{ notification.release_time }}</div>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; background-color: white; margin-top: 1%">
      <h3 style="text-align: left; color: #2D2F33; margin-left: 15px; margin-top: 15px">任务清单</h3>
      <div class="task-list-container">
        <ul class="task-list">
          <li v-for="user_state in user_stateList" :key="user_state.major_id" class="task-item">
            <div style="margin-left: 5px; color: #5A5E66;">{{ user_state.major_CN_name }}</div>
            <i class="iconfont icon-weitijiao" v-if="user_state.is_submit == 0">未提交</i>
            <i class="iconfont icon-yiwancheng" v-else-if="user_state.is_checked_by_college == 1">已批阅</i>
            <i class="iconfont icon-weiwancheng" style="color: blue;" v-else>
              <router-link :to="`/college-admin/check/${user_state.major_id}`" style="color: blue; text-decoration: none;">未批阅
              </router-link>
            </i>
          </li>
        </ul>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: right; margin-top: 10px; margin-bottom: 10px">
        <el-button type="success" round style="width: 8%; background-color: #71246B" v-if="allTasksCompleted && this.submit == 0" @click="handleSubmit">提交</el-button>
        <el-button type="info" round style="width: 8%; background-color: #71246B" v-else-if="allTasksCompleted && this.submit == 1" @click="showSubmitMessage">已提交</el-button>
        <el-button type="info" round style="width: 8%" v-else @click="showErrorMessage">无法提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      majorList: [],
      college: {},
      submit:0,
      notification: {
        content: '',
        release_time: '',
      },
      user_stateList: [
      ]
    };
  },
  computed: {
    majorNames() {
      return this.majorList.map(major => major.major_CN_name).join(', ');
    },
    allTasksCompleted() {
      return this.user_stateList.every(user_state => user_state.is_checked_by_college === 1);
    }
  },
  created() {
    this.$http.get('/getNotification').then((response) => {
      this.notification.release_time = response.data.data.release_time;
      this.notification.content = response.data.data.content;
    })
    this.$http.post("/getCollegeInfo", {college_id: localStorage.getItem('college_id')})
        .then(response => {
          this.college = response.data.data.collegeInfo
          console.log(this.college)
          this.majorList = response.data.data.majorInfoList
          console.log(this.majorList)
          this.user_stateList = response.data.data.user_stateList
        })
    this.$http.post('getCollegeSubmit',{college_id: localStorage.getItem('college_id')})
        .then(response=>{
          this.submit = response.data.data.is_submit;
        })
  },
  methods: {
    showSubmitMessage(){
      this.$message.error('请勿重复提交!');
    },
    showErrorMessage() {
      this.$message.error('请完成所有任务后提交!');
    },
    handleSubmit(){
      this.$http.post('/submitCollege',{college_id:localStorage.getItem('college_id')})
          .then(response=>{
            this.$message.success("提交成功！");
          })
          .catch(error=>{
            this.$message.error("提交失败！")
          })
    }
  }
}
</script>

<style>
.task-list-container {
  width: 100%; /* 设定清单容器的固定宽度 */
  height: 40vh; /* 设定清单容器的固定高度 */
  overflow-y: scroll; /* 使清单内容超出容器高度时可滚动 */
  border: 1px solid #ccc;
}

.task-list {
  list-style-type: none; /* 去除列表默认样式 */
  padding: 0; /* 去除列表的内边距 */
  margin: 0; /* 去除列表的外边距 */
}

.task-item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
