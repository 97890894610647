import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import SaMenu from "@/components/school-admin/SaMenu.vue";
import CaMenu from "@/components/college-admin/CaMenu.vue";
import MaMenu from "@/components/major-admin/MaMenu.vue";
import Password from "@/components/Password.vue";
import MaMenuContent from "@/components/major-admin/MaMenuContent.vue";
import Others from "@/components/major-admin/TrainingPlan/Others.vue";
import Requirements from "@/components/major-admin/TrainingPlan/Requirements.vue";
import TrainingObjective from "@/components/major-admin/TrainingPlan/TrainingObjective.vue";
import Course from "@/components/major-admin/Course.vue";
import Print from "@/components/major-admin/Print.vue";
import Table from "@/components/major-admin/Table.vue";
import Table1 from "@/components/major-admin/Tables/Table1.vue";
import Table2 from "@/components/major-admin/Tables/Table2.vue";
import Table3 from "@/components/major-admin/Tables/Table3.vue";
import Table4 from "@/components/major-admin/Tables/Table4.vue";
import Table5 from "@/components/major-admin/Tables/Table5.vue";
import Table6 from "@/components/major-admin/Tables/Table6.vue";
import Table7 from "@/components/major-admin/Tables/Table7.vue";
import Table8 from "@/components/major-admin/Tables/Table8.vue";
import CaMenuContent from "@/components/college-admin/CaMenuContent.vue";
import MajorCheck from "@/components/college-admin/MajorCheck.vue";
import SaMenuContent from "@/components/school-admin/SaMenuContent.vue";
import CollegeCheck from "@/components/school-admin/CollegeCheck.vue";
import CollegeAndMajorManage from "@/components/school-admin/CollegeAndMajorManage.vue";
import EmployeeManage from "@/components/school-admin/EmployeeManage.vue";

Vue.use(VueRouter)

const routes = [
    //登录
    { path: "/", component: Login},

    //密码
    { path: "/password", component: Password},

    //校级管理员页面
    {
        path: '/school-admin',
        component: SaMenu,
        children: [
            { path: 'menu', component: SaMenuContent },
            { path: 'check/:college_id', component: CollegeCheck, props: true },
            { path: 'collegeAndMajorManage', component: CollegeAndMajorManage },
            { path: 'employeeManage', component: EmployeeManage }
        ]
    },

    //院级管理员页面
    {
        path: "/college-admin",
        component: CaMenu,
        children: [
            { path: "menu", component: CaMenuContent},
            { path: '/college-admin/check/:major_id', component: MajorCheck}
        ]
    },

    //系级管理员页面
    {
        path: "/major-admin",
        component: MaMenu,
        children: [
            { path: "menu", component: MaMenuContent }, // 默认子路由
            { path: "others", component: Others },
            { path: "requirements", component: Requirements },
            { path: "trainingObjective", component: TrainingObjective },
            { path: "course", component: Course },
            { path: "print", component: Print },
            { path: "table", component: Table,
                children: [
                    {path: "table1", component: Table1},
                    {path: "table2", component: Table2},
                    {path: "table3", component: Table3},
                    {path: "table4", component: Table4},
                    {path: "table5", component: Table5},
                    {path: "table6", component: Table6},
                    {path: "table7", component: Table7},
                    {path: "table8", component: Table8},
                ]},
        ]
    },
]

const router = new VueRouter({
    routes
})

export default router;
