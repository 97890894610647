<template>
  <div style="margin: 1%;display: flex;flex-direction: column">
    <div style="display: flex;flex-direction: row;justify-content: left">
      <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i><div style="font-size: 12px;color:grey;margin-top: -5px">首页</div>
    </div>
    <div style="display: flex;flex-direction: row;margin-top: 5px">
      <div style="display: flex;flex-direction: column;background-color: white;width: 44%;height: 25vh">
        <h3 style="text-align: left;color: #2D2F33;margin-left:15px;margin-top:15px;font-size: 16px">提交与审核情况</h3>
        <hr style="background-color: #808080; width: 95%;margin-top:-5px;">
        <div style="text-align: left;margin-left:15px;margin-top:5px;font-size: 16px"><b>学院提交情况:  </b><span style="color:#5A5E66;">{{ submitColleges }} / {{ totalColleges }}</span></div>
        <div style="text-align: left;margin-left:15px;margin-top:15px;font-size: 16px"><b>当前审核进度: </b><span style="color:#5A5E66;">{{ checkColleges }} / {{ submitColleges }}</span></div>
      </div>
      <div style="display: flex;flex-direction: column;justify-content: space-between; background-color: white;width: 55%;height: 25vh;margin-left: 1%">
        <div>
          <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center">
            <h3 style="text-align: left;color: #2D2F33;margin-left:15px;margin-top:15px;font-size: 16px">通知</h3>
            <el-button style="background-color: #71246B;height: 80%;margin-right: 10px;color: #FFFFFF" @click="changeNotification">修改</el-button>
          </div>
          <el-dialog title="修改通知" :visible.sync="changeVisibleForm" :modal="true" :modal-append-to-body="false">
            <el-form>
              <el-form-item>
                <h3 style="text-align: left;">请输入通知内容:</h3>
                <textarea v-model="notification.content" style="color: #5A5E66; height: 100px;width: 80%;border-radius: 4px; background-color: #FFF; border: 1px solid #DCDFE6;resize: none;font-size: 16px;line-height: 2;padding: 0 15px;outline-color: rgba(64,158,255);"></textarea>
              </el-form-item>
              <el-form-item style="margin-top: 20px;">
                <el-button type="primary" @click="handleChangeNotification"  style="background-color: #71246B">提交</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
          <hr style="background-color: #808080; width: 95%;margin-top:-5px;">
          <div style="color: #5A5E66;font-size: 15px;text-align: left;margin-left:15px;">{{this.notification.content}}</div>
        </div>
        <div>
          <div style="color: #5A5E66;font-size: 15px;text-align: right;margin-right:15px;">教务处</div>
          <div style="color: #5A5E66;font-size: 15px;text-align: right;margin-right:15px;">{{this.notification.release_time}}</div>
        </div>
      </div>
    </div>
    <div style="display: flex;flex-direction: column;background-color: white;margin-top: 1%">
      <h3 style="text-align: left; color: #2D2F33;margin-left:15px;margin-top:15px">任务清单</h3>
      <div class="task-list-container">
        <ul class="task-list">
          <li v-for="user_state in user_stateList" class="task-item">
            <div style="margin-left: 5px;color: #5A5E66;">{{user_state.college_name}}</div>
            <i class="iconfont icon-weitijiao" v-if="user_state.is_submit == 0">未提交</i>
            <i class="iconfont icon-yiwancheng" v-else-if="user_state.is_checked_by_school == 1">已批阅</i>
            <i class="iconfont icon-weiwancheng" style="color: blue;" v-else>
              <router-link :to="`/school-admin/check/${user_state.college_id}`" style="color: blue; text-decoration: none;">未批阅
              </router-link>
            </i>
          </li>
        </ul>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: right; margin-top: 10px; margin-bottom: 10px">
        <el-button type="success" round style="width: 8%; background-color: #71246B" v-if="allTasksCompleted">封存</el-button>
        <el-button type="info" round style="width: 8%" v-else @click="showErrorMessage">无法封存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalColleges:'',
      submitColleges:'',
      checkColleges:'0',
      changeVisibleForm:false,
      collegeList: [],
      notification: {
        content: '',
        release_time: '',
      },
      user_stateList: [
        // {
        //   college_id: "1",
        //   college_name: "计算机科学与技术学院",
        //   is_checked_by_school:0
        // },
        // {
        //   college_id: "2",
        //   college_name: "软件学院",
        //   is_checked_by_school:0
        // },
      ]
    };
  },
  created() {
    this.$http.get("/getSubmitState").then(response=>{
      this.totalColleges = response.data.data.totalColleges;
      this.submitColleges = response.data.data.submitColleges;
      this.checkColleges = response.data.data.checkColleges;
    })
    this.$http.get('/getNotification').then((response)=>{
      this.notification.release_time=response.data.data.release_time;
      this.notification.content=response.data.data.content;
    })
    this.$http.get('/getUserStateList').then(response=>{
      this.user_stateList = response.data.data.user_stateList;
      console.log(this.user_stateList)
    })
  },
  computed:{
    allTasksCompleted() {
      console.log(this.user_stateList.every(user_state => user_state.is_checked_by_school == 1))
      return this.user_stateList.every(user_state => user_state.is_checked_by_school == 1);
    }
  },
  methods: {
    showErrorMessage() {
      this.$message.error('请完成所有任务后封存!');
    },

    changeNotification(){
      this.changeVisibleForm = true
    },
    handleChangeNotification(){
      this.changeVisibleForm = false
      this.$http.post("/changeNotification",{notification:this.notification})
          .then(response=>{
            this.$message.success("修改成功！");
          })
          .catch(error=>{
            this.$message.error("修改失败！");
          })
    }
  }
}
</script>

<style>
.task-list-container {
  width: 100%; /* 设定清单容器的固定宽度 */
  height: 40vh; /* 设定清单容器的固定高度 */
  overflow-y: scroll; /* 使清单内容超出容器高度时可滚动 */
  border: 1px solid #ccc;
}

.task-list {
  list-style-type: none; /* 去除列表默认样式 */
  padding: 0; /* 去除列表的内边距 */
  margin: 0; /* 去除列表的外边距 */
}

.task-item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
