<template>
  <div style="display: flex;flex-direction: column; justify-content: center;align-items: center;width: 100%">
    <h4>学历表</h4>
    <div style="width: 100%;max-width: 1100px; display: flex;justify-content: center;align-items: center">
      <el-table border :data="tableData" :min-height="tableHeight" :max-height="tableHeight" style="width: 90%">
        <!-- 学年列 -->
        <el-table-column prop="year_" label="学年" width="50">
        </el-table-column>
        <!-- 第一学期列 -->
        <el-table-column prop="week_1" label="第一学期" width="560">
          <!-- 子列，每个子列代表一周 -->
          <el-table-column v-for="(week, index) in 20" :key="index" width="28">
            <!-- 表头 -->
            <template slot="header">
              <span style="font-size: 10px;margin:-4px">{{ index + 1 }}</span>
            </template>
            <!-- 单元格内容 -->
            <template slot-scope="scope">
              <el-input v-model="scope.row.week_1[index]" style="width: 27px"></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <!-- 寒假列 -->
        <el-table-column label="寒假" width="50">
          <template slot-scope="scope">
            <span style="font-size: 10px">5周</span>
          </template>
        </el-table-column>
        <!-- 第二学期列 -->
        <el-table-column prop="week_2" label="第二学期" width="560">
          <!-- 子列，每个子列代表一周 -->
          <el-table-column v-for="(week, index) in 20" :key="index" width="28">
            <!-- 表头 -->
            <template slot="header">
              <span style="font-size: 10px;margin:-4px">{{ index + 1 }}</span>
            </template>
            <!-- 单元格内容 -->
            <template slot-scope="scope">
              <el-input v-model="scope.row.week_2[index]" style="width: 27px"></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <!-- 暑假列 -->
        <el-table-column label="暑假" width="50">
          <template slot-scope="scope">
            <span style="font-size: 10px">9周</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex;flex-direction: column;justify-content: left;align-items: flex-start">
      <h6 style="margin:10px">一、点击单元格输入任务编号，输入任务编号时请使用英文逗号隔开</h6>
      <h6 style="margin:10px">二、各任务编号如下：1：入学、毕业教育 2：理论教学 3：实习或其他实践 4：机动 5：考试 6：毕业设计（论文）7：军事技能 8：十.一</h6>
    </div>
    <div style="margin-top: 10px;margin-bottom: 10px;display: flex;justify-content: center;">
      <el-button type="primary" @click="handleSubmit()" style="background-color: #71246B;">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      tableHeight:null,
    };
  },
  mounted() {
    this.tableHeight = window.innerHeight - 320;
    // 后面的50：根据需求空出的高度，自行调整
  },
  methods: {
    handleSubmit() {
      // Deep copy tableData and transform week_1 and week_2 arrays to comma-separated strings
      const transformedData = this.tableData.map(row => ({
        id:row.id,
        year_: row.year_,
        week_1: row.week_1.join('/'),
        week_2: row.week_2.join('/')
      }));

      this.$http.post('/table3/submitTasks', {
        major_id: localStorage.getItem('major_id'),
        enrollment_year: localStorage.getItem('enrollment_year'),
        tasks: transformedData
      })
          .then(response => {
            this.$message.success('保存成功！');
          })
          .catch(error => {
            this.$message.error('保存失败！');
          });

      this.$http.post('/finishTable1', { major_id: localStorage.getItem('major_id') })
    },
  },
  created() {
    this.$http.post('/table3/getTasks', {
      major_id: localStorage.getItem('major_id'),
      enrollment_year: localStorage.getItem('enrollment_year')
    })
        .then(response => {
          // Check if week_1 or week_2 is empty and fill with empty strings if needed
          if(response.data.data.tasks.length === 0){
            this.tableData=[
              {
                id:-1,
                year_: 1, // 学年
                week_1: Array(20).fill(""), // Initialize with 20 empty strings
                week_2: Array(20).fill(""),
              },
              {
                id:-1,
                year_: 2, // 学年
                week_1: Array(20).fill(""),
                week_2: Array(20).fill(""),
              },
              {
                id:-1,
                year_: 3, // 学年
                week_1: Array(20).fill(""),
                week_2: Array(20).fill(""),
              },
              {
                id:-1,
                year_: 4, // 学年
                week_1: Array(20).fill(""),
                week_2: Array(20).fill(""),
              },
            ]
          }else{
            this.tableData = response.data.data.tasks.map(row => ({
              id: row.id,
              year_: row.year_,
              week_1: row.week_1.split('/'),
              week_2: row.week_2.split('/')
            }));
          }
          console.log(this.tableData)
        })
        .catch(error => {
          this.$message.error('获取失败');
        });
  }

};
</script>

<style scoped>
/deep/ .el-input__inner {
  padding: 0;
  width: 28px;
  font-size: 12px;
  border: none;
}
</style>
