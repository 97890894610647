<template>
  <div style="margin: 1%;display: flex;flex-direction: column">
    <div style="display: flex;flex-direction: row;justify-content: left">
      <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i>
      <div style="font-size: 12px;color:grey;margin-top: -5px">培养方案</div>
      <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i>
      <div style="font-size: 12px;color:grey;margin-top: -5px">毕业要求</div>
    </div>
    <div style="background-color: white;margin-top: 5px">
      <div>
        <el-table
            :data="processedRequirements"
            border
            :height="tableHeight"
            :max-height="tableHeight"
            style="width: 98%; margin-top: 20px; margin-left: 15px;"
        >
          <el-table-column prop="requirements" label="毕业要求">
            <template slot-scope="scope">
              <div>{{scope.$index + 1}}. {{scope.row.requirements}}</div>
            </template>
          </el-table-column>
          <el-table-column label="毕业要求细分">
            <template slot-scope="scope">
              <el-table :data="scope.row.details" :row-class-name="rowClassName" :show-header="false">
                <el-table-column>
                  <template slot-scope="detailScope">
                    <div>{{scope.$index + 1}}.{{detailScope.$index + 1}} {{detailScope.row.details}}</div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button @click="handleUpdate(scope)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
              <el-button @click="handleDelete(scope)" type="text" size="small" icon="el-icon-delete" style="color: red">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 8vh; display: flex;flex-direction: row;background-color: white;justify-content: right">
      <div style="width:16%; margin-right: 10px;  margin-top: 10px;display: flex;flex-direction: row;background-color: white;justify-content: space-between">
        <el-button type="primary" @click="handleAdd" style="width: 50%;height: 80%;background-color: #71246B">新增</el-button>
        <el-button type="primary" @click="handleSubmit" style="width: 50%;height: 80%;background-color: #71246B">保存</el-button>
      </div>
      <el-dialog title="新增毕业要求" :visible.sync="addRequirementsFormVisible" :modal="true" :modal-append-to-body="false">
        <el-form>
          <el-form-item>
            <h3 style="text-align: left;margin-left: 15px;margin-top: 0">毕业要求</h3>
            <el-input placeholder="请输入毕业要求" v-model="addRequirements">
              <template slot="prepend">{{processedRequirements.length + 1}}.</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div style="display: flex;flex-direction: row;justify-content: space-between">
              <h3 style="text-align: left;margin-left: 15px;margin-top: 0">毕业要求细分</h3>
              <el-button type="primary" @click="handleAddAddDetails" style="background-color: #71246B;height: 80%">新增细分</el-button>
            </div>
            <el-form-item v-for="(detail, index) in addDetails" :key="index" style="margin-top: 5px;margin-bottom: 5px">
              <el-input :placeholder="'请输入毕业要求细分' + (index + 1)" v-model="addDetails[index]">
                <template slot="prepend">{{processedRequirements.length + 1}}.{{index + 1}}</template>
                <template slot="append">
                  <i class="el-icon-delete" style="color: red" @click="handleDeleteAddDetails(index)"></i>
                </template>
              </el-input>
            </el-form-item>
          </el-form-item>
          <el-form-item style="margin-top: 20px;">
            <el-button type="primary" @click="AddRequirementsAndDetails" style="background-color: #71246B">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="编辑毕业要求" :visible.sync="editRequirementsFormVisible" :modal="true" :modal-append-to-body="false">
        <el-form>
          <el-form-item>
            <h3 style="text-align: left;margin-left: 15px;margin-top: 0">毕业要求</h3>
            <el-input placeholder="请输入毕业要求" v-model="editRequirements">
              <template slot="prepend">{{editRequirementsIndex}}.</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div style="display: flex;flex-direction: row;justify-content: space-between">
              <h3 style="text-align: left;margin-left: 15px;margin-top: 0">毕业要求细分</h3>
              <el-button type="primary" @click="handleAddEditDetails" style="background-color: #71246B;height: 80%">新增细分</el-button>
            </div>
            <el-form-item v-for="(detail, index) in editDetails" :key="index" style="margin-top: 5px;margin-bottom: 5px">
              <el-input :placeholder="'请输入毕业要求细分' + (index + 1)" v-model="editDetails[index]">
                <template slot="prepend">{{editRequirementsIndex}}.{{index + 1}}</template>
                <template slot="append">
                  <i class="el-icon-delete" style="color: red" @click="handleDeleteEditDetails(index)"></i>
                </template>
              </el-input>
            </el-form-item>
          </el-form-item>
          <el-form-item style="margin-top: 20px;">
            <el-button type="primary" @click="editRequirementsAndDetails" style="background-color: #71246B">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addRequirementsFormVisible: false,
      editRequirementsFormVisible: false, // 编辑
      requirements: [],
      details: [],
      addRequirements: '',
      addDetails: ['', '', ''],
      editRequirements: '',
      editRequirements_id: '',
      editRequirementsIndex: '',
      editDetails: [],
      tableHeight: null,
    };
  },
  mounted() {
    console.log(window.innerHeight);
    this.tableHeight = window.innerHeight - 200;
    // 后面的50：根据需求空出的高度，自行调整
  },
  computed: {
    processedRequirements() {
      return this.requirements.map(req => {
        const details = this.details.filter(detail => detail.requirements_id === req.requirements_id);
        return { ...req, details };
      });
    },
  },
  created() {
    this.$http
        .post('/getRequirements', {
          major_id: localStorage.getItem('major_id'),
          enrollment_year: localStorage.getItem('enrollment_year'),
        })
        .then(response => {
          this.requirements = response.data.data.requirements.map(req => {
            const firstSpaceIndex = req.requirements.indexOf(' ');
            return {
              ...req,
              requirements: req.requirements.substring(firstSpaceIndex + 1),
            };
          });
        })
        .catch(error => {
          this.$message.error('获取毕业要求失败！');
        });
    this.$http
        .post('/getRequirementsDetails', {
          major_id: localStorage.getItem('major_id'),
          enrollment_year: localStorage.getItem('enrollment_year'),
        })
        .then(response => {
          this.details = response.data.data.requirementsDetails.map(detail => {
            const firstSpaceIndex = detail.details.indexOf(' ');
            return {
              ...detail,
              details: detail.details.substring(firstSpaceIndex + 1),
            };
          });
        })
        .catch(error => {
          this.$message.error('获取毕业要求细分失败！');
        });
  },
  methods: {
    refresh() {
      this.$http
          .post('/getRequirements', {
            major_id: localStorage.getItem('major_id'),
            enrollment_year: localStorage.getItem('enrollment_year'),
          })
          .then(response => {
            this.requirements = response.data.data.requirements.map(req => {
              const firstSpaceIndex = req.requirements.indexOf(' ');
              return {
                ...req,
                requirements: req.requirements.substring(firstSpaceIndex + 1),
              };
            });
          })
          .catch(error => {
            this.$message.error('获取毕业要求失败！');
          });

      this.$http
          .post('/getRequirementsDetails', {
            major_id: localStorage.getItem('major_id'),
            enrollment_year: localStorage.getItem('enrollment_year'),
          })
          .then(response => {
            this.details = response.data.data.requirementsDetails.map(detail => {
              const firstSpaceIndex = detail.details.indexOf(' ');
              return {
                ...detail,
                details: detail.details.substring(firstSpaceIndex + 1),
              };
            });
          })
          .catch(error => {
            this.$message.error('获取毕业要求细分失败！');
          });
    },
    handleSubmit() {
      this.$http
          .post('/finishRequirements', { major_id: localStorage.getItem('major_id') })
          .then(response => {
            this.$message.success('保存成功！');
          })
          .catch(error => {
            this.$message.error('保存失败！');
          });
    },
    rowClassName({ row, rowIndex }) {
      return `row-${rowIndex}`;
    },
    AddRequirementsAndDetails() {
      let data = {
        major_id: localStorage.getItem('major_id'),
        enrollment_year: localStorage.getItem('enrollment_year'),
        requirements: `${this.processedRequirements.length + 1}. ${this.addRequirements}`,
        details: this.addDetails.map((detail, index) => `${this.processedRequirements.length + 1}.${index + 1} ${detail}`),
      };
      this.$http
          .post('/addRequirementsAndDetails', data)
          .then(response => {
            this.$message.success('新增毕业要求成功！');
            this.addRequirementsFormVisible = false;
            this.refresh();
          })
          .catch(error => {
            this.$message.error('新增毕业要求失败！');
          });
    },
    editRequirementsAndDetails() {
      let data = {
        major_id: localStorage.getItem('major_id'),
        enrollment_year: localStorage.getItem('enrollment_year'),
        requirements_id: this.editRequirements_id,
        requirements: `${this.editRequirementsIndex}. ${this.editRequirements}`,
        details: this.editDetails.map((detail, index) => `${this.editRequirementsIndex}.${index + 1} ${detail}`),
      };
      this.$http
          .post('/editRequirementsAndDetails', data)
          .then(response => {
            this.$message.success('修改毕业要求成功！');
            this.editRequirementsFormVisible = false;
            this.refresh();
          })
          .catch(error => {
            this.$message.error('修改毕业要求失败！');
          });
    },
    handleUpdate(scope) {
      this.editRequirementsFormVisible = true;
      this.editRequirements = scope.row.requirements;
      this.editRequirements_id = scope.row.requirements_id;
      this.editRequirementsIndex = scope.$index + 1;
      this.editDetails = scope.row.details.map(detail => detail.details);
    },
    handleAdd() {
      this.addRequirementsFormVisible = true;
      this.addRequirements = '';
      this.addDetails = ['', '', ''];
    },
    handleAddAddDetails() {
      this.addDetails.push('');
    },
    handleDeleteAddDetails(index) {
      this.addDetails.splice(index, 1);
    },
    handleAddEditDetails() {
      this.editDetails.push('');
    },
    handleDeleteEditDetails(index) {
      this.editDetails.splice(index, 1);
    },
    handleDelete(scope) {
      this.$confirm('此操作将永久删除该条毕业要求, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.$http
                .post('/deleteRequirementsAndDetails', { requirements_id: scope.row.requirements_id })
                .then(response => {
                  this.$message.success('删除成功！');
                  this.refresh();
                })
                .catch(error => {
                  this.$message.error('删除失败！');
                });
          })
          .catch(() => {
            this.$message.info('已取消删除');
          });
    },
  },
};
</script>

<style scoped>
.el-table th,
.el-table td {
  padding: 0;
  margin: 0;
}
</style>
