<template>
  <div style="width: 100%;display: flex;flex-direction: column; justify-content: center;align-items: center">
    <h4>时间分配（以周记）</h4>
    <el-table show-summary border :data="tableData" :min-height="tableHeight" :max-height="tableHeight" style="width: 721px;">
      <el-table-column prop="tasks" label="学年" width="120">
      </el-table-column>
      <el-table-column prop="one" label="I" width="120">
      </el-table-column>
      <el-table-column prop="two" label="II" width="120">
      </el-table-column>
      <el-table-column prop="three" label="III" width="120">
      </el-table-column>
      <el-table-column prop="four" label="IV" width="120">
      </el-table-column>
      <el-table-column prop="total" label="总计" width="120">
      </el-table-column>
    </el-table>
    <div style="width: 721px;display: flex;flex-direction: column;justify-content: left;align-items: flex-start">
      <h6 style="margin:10px">一、此表由学历表数据自动汇总，无法修改；如想修改，请前往表3修改</h6>
    </div>
    <div style="margin-top: 10px;margin-bottom: 10px;display: flex;justify-content: flex-end;width: 721px">
      <el-button type="primary" @click="handleSubmit()" style="background-color: #71246B;">确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        { tasks: '入学、毕业教育、军事技能', one: '', two: '', three: '', four: '', total: '' },
        { tasks: '理论教学', one: '', two: '', three: '', four: '', total: '' },
        { tasks: '考试', one: '', two: '', three: '', four: '', total: '' },
        { tasks: '实践环节', one: '', two: '', three: '', four: '', total: '' },
        { tasks: '毕业设计（论文）', one: '', two: '', three: '', four: '', total: '' },
        { tasks: '机动', one: '', two: '', three: '', four: '', total: '' },
        { tasks: '假期', one: 12, two: 12, three: 12, four: 5, total: 41 }
      ],
      tableHeight:null
    };
  },
  mounted() {
    this.tableHeight = window.innerHeight - 270;
  },
  created() {
    this.$http.post('/table7/getTasks', { major_id: localStorage.getItem('major_id'), enrollment_year: localStorage.getItem('enrollment_year') })
        .then(response => {
          const responseData = response.data.data.tableData;
          this.tableData.forEach(item => {
            const foundItem = responseData.find(data => data.tasks === item.tasks);
            if (foundItem) {
              item.one = foundItem.one || '';
              item.two = foundItem.two || '';
              item.three = foundItem.three || '';
              item.four = foundItem.four || '';
              item.total = foundItem.total || '';
            }
          });
        })
        .catch(error => {
          console.error('获取学历表失败！', error);
        });
  },
  methods: {
    handleSubmit() {
      this.$http.post("/finishTable5", { major_id: localStorage.getItem('major_id') })
          .then(() => {
            this.$message.success('确认成功');
          })
          .catch(() => {
            this.$message.error('确认失败');
          });
    }
  }
};
</script>
