import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from '../src/router'
import axios from 'axios'
import store from '../src/store/index'
import Vuex from "vuex";
import VueAxios from 'vue-axios';
import "../src/assets/iconfont/iconfont.css"
import "../src/assets/iconfont/iconfont"
// import "lib-flexible/flexible"
// import './utils/rem.js'


axios.defaults.baseURL="https://test.dreambo.cn/api"
// axios.defaults.baseURL="http://localhost:8081"

Vue.config.productionTip = false;
Vue.use(ElementUI);//全局引入
Vue.use(Vuex);
Vue.prototype.$http=axios;
Vue.use(VueAxios, axios);


new Vue({
  render: h => h(App),
  router:router,//注入路由
  store:store //全局变量
}).$mount('#app')
