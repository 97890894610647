<template>
  <div style="overflow: auto">
    <div style="margin: 1%; display: flex; flex-direction: column;">
      <div style="display: flex; flex-direction: row; justify-content: left">
        <i class="iconfont icon-normal" style="font-size: 12px; margin-top:-3px; color: grey"></i>
        <div style="font-size: 12px; color:grey; margin-top: -5px">预览与下载</div>
      </div>
      <div style="background-color: white; margin-top: 5px; display: flex; flex-direction: column; justify-content: center; align-items: center">
        <h4 style="margin: 10px">文件预览</h4>
        <div>
          <div v-if="loading" style="height: 540px; width: 1000px; border: 1px solid #ccc; display: flex; justify-content: center; align-items: center;">
            <i class="el-icon-loading"></i> 正在加载...
          </div>
          <vue-office-pdf
              v-else
              :src="pdfUrl"
              style="height: calc(100vh - 200px); width: 1000px; border: 1px solid #ccc;"
              @rendered="renderedHandler"
              @error="errorHandler"
          />
        </div>
        <div style="width:90%; display: flex; flex-direction: row; justify-content: right; margin:15px">
          <el-button type="primary" @click="handleScan" style="width:100px; height: 80%; background-color: #71246B">生成文档</el-button>
          <el-button type="primary" @click="handleLoad" style="width:100px; height: 80%; background-color: #71246B">下载</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueOfficePdf from '@vue-office/pdf'
import '@vue-office/pdf/lib/v3/index'

export default {
  components: {
    VueOfficePdf
  },
  data() {
    return {
      pdfUrl: '', // 设置PDF文件的网络地址，可以是相对地址
      wordUrl:'',
      loading: false // 加载状态
    }
  },
  created() {
    this.$http.post("/getOldURL",{major_id:localStorage.getItem("major_id"),enrollment_year:localStorage.getItem('enrollment_year')})
        .then(response=>{
          this.pdfUrl = response.data.data.pdfUrl;
          this.wordUrl = response.data.data.wordUrl;
          this.loading = false; // 关闭加载动画
        })
  },
  methods: {
    renderedHandler() {
      console.log("渲染完成")
    },
    errorHandler() {
      console.log("渲染失败")
    },
    handleScan() {
      this.loading = true; // 开始加载动画
      this.$message.success("请稍等10秒钟左右！")
      this.$http.post("/getURL", {major_id: localStorage.getItem("major_id"), enrollment_year: localStorage.getItem('enrollment_year')})
          .then(response => {
            setTimeout(() => {
              this.pdfUrl = response.data.data.pdfUrl;
              this.wordUrl = response.data.data.wordUrl;
              this.loading = false; // 关闭加载动画
            }, 10000); // 延时10秒
          });
    },
    handleLoad() {
      fetch(this.wordUrl)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = '培养方案.docx'; // 设置下载属性
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => console.error('Error fetching PDF:', error));
    },
    handleSubmit() {
      this.$message.success('提交成功');
    }
  }
}
</script>

<style scoped>
:deep(.vue-office-pdf-wrapper) {
  padding: 0 !important;
  background-color: transparent !important;
}
</style>
