<template>
  <div>
    <div class="top">
      <div style="margin-left: 1%">
        <img src="@/assets/picture/tg_logo_p.png" alt="" style="height: 40px;margin:4%">
      </div>
      <div style="display: flex;justify-content: center;align-items: center;width: 20%;color:white;font-size: 16px">
        欢迎使用培养方案修订系统！
      </div>
      <div style="width: 80%;display: flex;flex-direction: row-reverse">
        <div>
          <el-dropdown style="margin-top: 35%">
            <span class="el-dropdown-link" style="color: white">admin<i class="el-icon-arrow-down el-icon--right"></i></span>
            <el-dropdown-menu>
              <el-dropdown-item>
                <router-link to="/password" style="text-decoration: none; color: #606266;">修改密码
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="display: flex; justify-content: center; align-items: center; width: 3%;">
          <el-icon name="user-solid" style="color: white;font-size: 20px"></el-icon>
        </div>
        <div style="display: flex; justify-content: center; align-items: center; width: 3%;">
          <i class="iconfont icon-youxiang" style="color: white;font-size: 20px" @click="showWarning"></i>
          <el-dialog title="打回原因" :visible.sync="showWarningVisibleForm" :modal="true" :modal-append-to-body="false">
            <textarea v-model="user_state.warning" readonly style="color: #5A5E66; height: 100px;width: 80%;border-radius: 4px; background-color: #FFF; border: 1px solid #DCDFE6;resize: none;font-size: 16px;line-height: 2;padding: 0 15px;outline-color: rgba(64,158,255);"></textarea>
          </el-dialog>
        </div>
        <div style="margin-right: 1%;display: flex;justify-content: center;align-items: center;width: 10%;">
          <el-select v-model="enrollment_year">
            <el-option @click="changeEnrollment_year(enrollment_year)" v-for="enrollment_year in enrollment_year_list" :key="enrollment_year" :value="enrollment_year"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div style="display: flex;flex-direction: row;">
      <div class="catalogue">
        <el-menu style="height: 100%; width: 100%;" background-color="#71246B" text-color="#ffffff" default-active="1">
          <router-link to="/major-admin/menu"><el-menu-item index="1" style="color: white" align="left"><i class="el-icon-s-home" style="color: white"></i>首页</el-menu-item></router-link>
          <el-submenu index="2" align="left">
            <template slot="title"><i class="el-icon-s-order" style="color: white"></i><span>培养方案</span></template>
            <router-link to="/major-admin/trainingObjective"><el-menu-item index="2-1" align="left" class="item">培养目标</el-menu-item></router-link>
            <router-link to="/major-admin/requirements"><el-menu-item index="2-2" align="left" class="item">毕业要求</el-menu-item></router-link>
            <router-link to="/major-admin/others"><el-menu-item index="2-3" align="left" class="item">其他</el-menu-item></router-link>
          </el-submenu>
          <router-link to="/major-admin/course"><el-menu-item index="3" style="color: white" align="left"><i class="el-icon-collection" style="color: white"></i><span>课程</span></el-menu-item></router-link>
          <router-link to="/major-admin/table"><el-menu-item index="4" style="color: white" align="left"><i class="el-icon-s-grid" style="color: white"></i><span>表格编排</span></el-menu-item></router-link>
          <router-link to="/major-admin/print"><el-menu-item index="5" style="color: white" align="left"><i class="el-icon-printer" style="color: white"></i><span>预览与下载</span></el-menu-item></router-link>
        </el-menu>
      </div>
      <div class="right">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      user_state:{
        employee_id:'',
        warning:''
      },
      showWarningVisibleForm:false,
      enrollment_year:localStorage.getItem('enrollment_year'),
      enrollment_year_list:[2023,2024],
    }
  },
  created() {
    this.$http.post("/getUserState",{employee_id:localStorage.getItem('employee_id')}).then(response=>{
      this.user_state = response.data.data;
    })
  },
  methods: {
    showWarning(){
      this.showWarningVisibleForm=true;
    },
    handleLogout(){
      this.$router.push("/");
    },
    changeEnrollment_year(enrollment_year){
      localStorage.setItem('enrollment_year',this.enrollment_year)
    }
  },
}
</script>

<style>
.top{
  height: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #71246B;
}
.catalogue {
  width: 15%;
  height: 100vh;
  min-width: 180px; /* 设置一个固定的最小宽度 */
  max-width: 18%; /* 限制最大宽度，以防止内容过多时过度扩展 */
  display: flex;
  flex-direction: row;
  background-color: #71246B;
}
.right{
  width: 100%;
  height: 100vh;
  background-color: rgb(242,243,245);
}
.el-menu {
  border-right-width: 0;
}
.item{
  background-color: #83467F !important;
}
.el-submenu .el-menu-item {
  min-width: 0 !important;
}
</style>
