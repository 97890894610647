<template>
  <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center">
    <h4>课程体系对毕业要求的支撑关系</h4>
    <el-table :data="courses" :min-height="tableHeight" :max-height="tableHeight"  show-summary :summary-method="getSummaries" border style="width: 100%;max-width: 1150px;">
      <el-table-column label="课程/毕业要求" width="150" fixed>
        <template slot-scope="scope">
          <span>{{ scope.row.course_name }}</span>
        </template>
      </el-table-column>
      <el-table-column v-for="(requirement, index) in requirementsList" :key="requirement.requirements_id" :label="'毕业要求' + (index + 1)">
        <el-table-column v-for="(details, detailsIndex) in filterRequirementsDetails(requirement.requirements_id)" :label="String(detailsIndex + 1)" :key="details.id" style="display: inline-block;">
          <template slot-scope="scope">
            <template v-if="editMode && editMode === scope.row.course_id">
              <input type="text" v-model="editedSupports[requirement.requirements_id][details.id]"/>
            </template>
            <template v-else>
              <span>{{ isSupported(scope.row, requirement.requirements_id, details.id) ? 1 : 0 }}</span>
            </template>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="操作" width="50" fixed="right">
        <template slot-scope="scope">
          <el-button v-if="editMode === scope.row.course_id" @click="saveUpdate(scope)" type="text" size="small" icon="el-icon-check">保存</el-button>
          <el-button v-else @click="handleUpdate(scope)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px; margin-bottom: 10px; display: flex; justify-content: center;">
      <el-button type="primary" @click="handleSubmit" style="background-color: #71246B;">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableHeight: null,
      requirementsList: [],
      requirementsDetailsList: [],
      courses: [],
      editMode: null,
      editedSupports: {}
    };
  },
  mounted() {
    this.tableHeight = window.innerHeight - 250;
  },
  methods: {
    isSupported(course, requirements_id, details_id) {
      if (!Array.isArray(course.supports)) {
        course.supports = [];
      }
      return course.supports.some(item => item.requirements_id === requirements_id && item.details_id === details_id);
    },
    getSummaries({ columns, data }) {
      const summaries = [' '];

      this.requirementsList.forEach(requirement => {
        this.filterRequirementsDetails(requirement.requirements_id).forEach(detail => {
          const count = data.reduce((acc, course) => {
            return this.isSupported(course, requirement.requirements_id, detail.id) ? acc + 1 : acc;
          }, 0);
          summaries.push(count);
        });
      });

      return summaries;
    },
    handleSubmit() {
      this.$http.post('/finishSupports2', { major_id: localStorage.getItem('major_id') });
      this.$http.post('/table2/submitTable2', { major_id: localStorage.getItem('major_id'), courses: this.courses })
          .then(response=>{
            this.$message.success('提交成功！');
          })
          .catch(error=>{
            this.$message.error("提交失败！")
          })
    },
    filterRequirementsDetails(requirements_id) {
      return this.requirementsDetailsList.filter(detail => detail.requirements_id === requirements_id);
    },
    handleUpdate(scope) {
      this.editMode = scope.row.course_id;
      this.editedSupports = {};

      this.requirementsList.forEach(requirement => {
        this.editedSupports[requirement.requirements_id] = {};
        this.filterRequirementsDetails(requirement.requirements_id).forEach(detail => {
          const supported = this.isSupported(scope.row, requirement.requirements_id, detail.id) ? 1 : 0;
          this.editedSupports[requirement.requirements_id][detail.id] = supported;
        });
      });
    },
    saveUpdate(scope) {
      const course = this.courses.find(c => c.course_id === scope.row.course_id);

      course.supports = [];
      Object.keys(this.editedSupports).forEach(requirements_id => {
        Object.keys(this.editedSupports[requirements_id]).forEach(details_id => {
          if (this.editedSupports[requirements_id][details_id] == 1) {
            course.supports.push({ requirements_id: parseInt(requirements_id), details_id: parseInt(details_id) });
          }
        });
      });

      this.editMode = null;
      this.$message.success('编辑保存成功！');
    }
  },
  created() {
    // 获取毕业要求
    this.$http.post('/getRequirements', {
      major_id: localStorage.getItem('major_id'),
      enrollment_year: localStorage.getItem('enrollment_year')
    }).then(response => {
      this.requirementsList = response.data.data.requirements;
    }).catch(() => {
      this.$message.error('获取毕业要求失败！');
    });

    // 获取毕业要求细分
    this.$http.post('/getRequirementsDetails', {
      major_id: localStorage.getItem('major_id'),
      enrollment_year: localStorage.getItem('enrollment_year')
    }).then(response => {
      this.requirementsDetailsList = response.data.data.requirementsDetails;
    }).catch(() => {
      this.$message.error('获取毕业要求细分失败！');
    });

    // 获取课程列表并确保 supports 属性初始化为数组
    this.$http.post('/getCourses', {
      major_id: localStorage.getItem('major_id'),
      enrollment_year: localStorage.getItem('enrollment_year')
    }).then(response => {
      this.courses = response.data.data.courses;
      this.courses.forEach(course => {
        try {
          // 替换 = 为 : 并将单引号替换为双引号
          const formattedSupports = course.supports
              .replace(/=/g, ':')
              .replace(/([a-zA-Z_]+)\s*:/g, '"$1":')
              .replace(/'/g, '"');
          course.supports = JSON.parse(formattedSupports);
        } catch (e) {
          course.supports = [];
        }
      });
    }).catch(() => {
      this.$message.error('获取课程列表失败！');
    });
  }
};
</script>
