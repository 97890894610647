<template>
  <div style="width: 100%;display: flex;flex-direction: column; justify-content: center;align-items: center">
    <h4>学生应修各类课程学分统计表</h4>
    <el-table  border :data="tableData" min-height="500px" max-height="500px" style="width: 1171px;">
      <el-table-column label="学分/类型" width="100">
        <template>
          <template>
            <span>学分数</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="typeA" label="通识选修课(A)" width="120">
      </el-table-column>
      <el-table-column prop="typeB" label="学科基础课(B)" width="120">
      </el-table-column>
      <el-table-column prop="typeC" label="专业基础课(C)" width="120">
      </el-table-column>
      <el-table-column prop="typeZ" label="专业课(Z)" width="120">
      </el-table-column>
      <el-table-column prop="typeD" label="独立实践环节(D)" width="150">
      </el-table-column>
      <el-table-column prop="typeE" label="专业选修课(E)" width="120">
      </el-table-column>
      <el-table-column prop="typeF" label="通识选修课(F)" width="120">
      </el-table-column>
      <el-table-column prop="total" label="合计(A+B+C+D+E+F+Z)" width="200">
      </el-table-column>
    </el-table>
    <div style="width: 1171px;display: flex;flex-direction: column;justify-content: left;align-items: flex-start">
      <h6 style="margin:10px">一、如专业不分方向，表中“专业方向课”改为“专业课（Z）”，并删除Z2行。</h6>
      <h6 style="margin:10px">二、此表由课程数据自动汇总，无法修改；如想修改，请前往课程板块修改</h6>
   </div>
    <div style="margin-top: 10px;margin-bottom: 10px;display: flex;justify-content: flex-end;width: 1171px">
      <el-button type="primary" @click="handleSubmit()"  style="background-color: #71246B;">确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      courses:[],
      practices : [],
      tableData:[
        {
          typeA:0,
          typeB:0,
          typeC:0,
          typeZ:0,
          typeD:0,
          typeE:10,
          typeF:10,
          total:0
        }
      ]
    }
  },
  created() {
      this.$http.post('/table6/getPractices',{major_id:localStorage.getItem('major_id'),enrollment_year:localStorage.getItem('enrollment_year')})
          .then(response=>{
            this.practices = response.data.data.practices
          }).catch('获取实践列表失败！')
    // 发起HTTP请求获取课程数据
    this.$http.post('/getCourses', { major_id: localStorage.getItem('major_id'), enrollment_year: localStorage.getItem('enrollment_year') })
        .then(response => {
          // 将课程数据保存到courses数组中
          this.courses = response.data.data.courses;
          // 调用更新表格数据的方法
          this.updateTableData();
        })
        .catch(error => {
          console.error('获取课程列表失败！', error);
          // 处理获取数据失败的情况
        });
  },
  methods: {
    updateTableData() {
      // 计算typeA是courses中所有course_type为通识必修课A的元素的credits之和
      this.tableData[0].typeA = this.courses.reduce((total, course) => {
        if (course.course_type === '通识必修课A') {
          return total + course.credits;
        } else {
          return total;
        }
      }, 0);

      // 计算typeB是courses中所有course_type为学科基础课B的元素的credits之和
      this.tableData[0].typeB = this.courses.reduce((total, course) => {
        if (course.course_type === '学科基础课B') {
          return total + course.credits;
        } else {
          return total;
        }
      }, 0);

      // 计算typeC是courses中所有course_type为专业基础课C的元素的credits之和
      this.tableData[0].typeC = this.courses.reduce((total, course) => {
        if (course.course_type === '专业基础课C') {
          return total + course.credits;
        } else {
          return total;
        }
      }, 0);

      // 计算typeZ是courses中所有course_type为专业方向课Z的元素的credits之和
      this.tableData[0].typeZ = this.courses.reduce((total, course) => {
        if (course.course_type === '专业方向课Z') {
          return total + course.credits;
        } else {
          return total;
        }
      }, 0);

      // 计算typeD是practices中所有元素的credit之和
      this.tableData[0].typeD = this.practices.reduce((total, practice) => {
        return total + practice.credit;
      }, 0);

      // 计算total是所有type之和
      this.tableData[0].total = this.tableData[0].typeA + this.tableData[0].typeB + this.tableData[0].typeC +
          this.tableData[0].typeZ + this.tableData[0].typeD + this.tableData[0].typeE + this.tableData[0].total;
    },
    handleSubmit() {
      this.$http.post('/finishTable4',{major_id:localStorage.getItem('major_id')})
          .then(response=>{
            this.$message.success("保存成功！");
          })
          .catch(error=>{
            this.$message.error("保存失败！")
          })
    }
  }
}
</script>
