<template>
  <div style="overflow: auto">
    <div style="margin: 1%;display: flex;flex-direction: column;">
      <div style="display: flex;flex-direction: row;justify-content: left">
        <i class="iconfont icon-normal" style="font-size: 12px;margin-top:-3px;color: grey"></i>
        <div style="font-size: 12px;color:grey;margin-top: -5px">审核</div>
      </div>
      <div style="background-color: white;margin-top: 5px;display: flex;flex-direction: column;justify-content: center;align-items: center">
        <h4 style="margin: 10px">文件预览</h4>
        <div v-if="pdfUrl">
          <vue-office-pdf
              :src="pdfUrl"
              style="height: calc(100vh - 200px); width: 1000px; border: 1px solid #ccc;"
              @rendered="renderedHandler"
              @error="errorHandler"
              key="pdf-preview"
          />
        </div>
        <div v-else style="height: calc(100vh - 200px); width: 1000px; display: flex; align-items: center; justify-content: center; border: 1px solid #ccc;">
          该用户尚未提交
        </div>
        <div style="width:90%; display: flex;flex-direction: row; justify-content: right;margin:15px">
          <el-button type="primary" @click="handleLoad" style="height: 80%;background-color: #71246B">下载</el-button>
          <el-button type="primary" @click="handleReject" style="height: 80%;background-color: #71246B">打回</el-button>
          <el-button type="primary" @click="handlePass" style="height: 80%;background-color: #71246B">通过</el-button>
        </div>
        <el-dialog title="打回原因" :visible.sync="rejectVisibleForm" :modal="true" :modal-append-to-body="false" width="70%">
          <el-form>
            <el-form-item>
              <h3 style="text-align: left;">不合格部分:</h3>
              <el-checkbox-group v-model="selectedCheckboxes">
                <div class="checkbox-container">
                  <div class="checkbox-column">
                    <el-checkbox label="submit_training_objective" @change="handleCheckboxChange('submit_training_objective')">培养目标</el-checkbox>
                    <el-checkbox label="submit_requirements" @change="handleCheckboxChange('submit_requirements')">毕业要求</el-checkbox>
                    <el-checkbox label="submit_supports1" @change="handleCheckboxChange('submit_supports1')">毕业要求对培养目标的支撑关系</el-checkbox>
                    <el-checkbox label="submit_core_subject" @change="handleCheckboxChange('submit_core_subject')">主干学科</el-checkbox>
                    <el-checkbox label="submit_core_knowledge_areas" @change="handleCheckboxChange('submit_core_knowledge_areas')">核心知识领域</el-checkbox>
                    <el-checkbox label="submit_major_core_courses" @change="handleCheckboxChange('submit_major_core_courses')">专业核心课程</el-checkbox>
                  </div>
                  <div class="checkbox-column">
                    <el-checkbox label="submit_core_practical_teaching" @change="handleCheckboxChange('submit_core_practical_teaching')">主要实践性教学环节</el-checkbox>
                    <el-checkbox label="submit_core_experiments" @change="handleCheckboxChange('submit_core_experiments')">主要专业实验</el-checkbox>
                    <el-checkbox label="submit_study_duration" @change="handleCheckboxChange('submit_study_duration')">修业年限</el-checkbox>
                    <el-checkbox label="submit_awarded_degree" @change="handleCheckboxChange('submit_awarded_degree')">授予学位</el-checkbox>
                    <el-checkbox label="submit_supports2" @change="handleCheckboxChange('submit_supports2')">课程体系对毕业要求的支撑关系</el-checkbox>
                    <el-checkbox label="submit_table1" @change="handleCheckboxChange('submit_table1')">学历表</el-checkbox>
                  </div>
                  <div class="checkbox-column">
                    <el-checkbox label="submit_table2" @change="handleCheckboxChange('submit_table2')">各必修、限选课程学时、学分分配统计表</el-checkbox>
                    <el-checkbox label="submit_table3" @change="handleCheckboxChange('submit_table3')">专业实习、课程设计或其他实践</el-checkbox>
                    <el-checkbox label="submit_table4" @change="handleCheckboxChange('submit_table4')">学生应修各类课程学分统计表</el-checkbox>
                    <el-checkbox label="submit_table5" @change="handleCheckboxChange('submit_table5')">时间分配（以周记）</el-checkbox>
                    <el-checkbox label="submit_table6" @change="handleCheckboxChange('submit_table6')">指导性教学计划</el-checkbox>
                  </div>
                </div>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item>
              <h3 style="text-align: left;">备注:</h3>
              <textarea v-model="user_state.warning" style="color: #5A5E66; height: 100px;width: 80%;border-radius: 4px; background-color: #FFF; border: 1px solid #DCDFE6;resize: none;font-size: 16px;line-height: 2;padding: 0 15px;outline-color: rgba(64,158,255);"></textarea>
            </el-form-item>
            <el-form-item style="margin-top: 20px;">
              <el-button type="primary" @click="handleRejectReason" style="background-color: #71246B">提交</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import VueOfficePdf from '@vue-office/pdf'
import '@vue-office/pdf/lib/v3/index'

export default {
  components: {
    VueOfficePdf
  },
  data() {
    return {
      user_state: {
        employee_id: '',
        is_submit: '',
        is_audit: '',
        enrollment_year: localStorage.getItem("enrollment_year"),
        submit_training_objective: '',
        submit_requirements: '',
        submit_supports1: '',
        submit_core_subject: '',
        submit_core_knowledge_areas: '',
        submit_major_core_courses: '',
        submit_core_practical_teaching: '',
        submit_core_experiments: '',
        submit_study_duration: '',
        submit_awarded_degree: '',
        submit_course:'',
        submit_supports2: '',
        submit_table1: '',
        submit_table2: '',
        submit_table3: '',
        submit_table4: '',
        submit_table5: '',
        submit_table6:'',
        warning: '',
      },
      selectedCheckboxes: [],
      rejectVisibleForm: false,
      pdfUrl: '',
      wordUrl: '',
      major_id: this.$route.params.major_id,
      loading: false
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    '$route.params.major_id': 'fetchData'
  },
  methods: {
    fetchData() {
      this.pdfUrl = ''; // 临时清空PDF URL
      this.wordUrl = '';

      this.$http.post("/getOldURL", { major_id: this.$route.params.major_id, enrollment_year: localStorage.getItem('enrollment_year') })
          .then(response => {
            if (response.data.success && response.data.code === 20000) {
              this.pdfUrl = response.data.data.pdfUrl;
              this.wordUrl = response.data.data.wordUrl;
            } else {
              this.$message.error("该用户尚未提交");
            }
          })
          .catch(() => {
            this.$message.error("获取数据失败");
          });
    },
    renderedHandler() {
      console.log("渲染完成");
    },
    errorHandler() {
      console.log("渲染失败");
    },
    handleLoad() {
      if (!this.wordUrl) {
        this.$message.error('文件未提交，无法下载');
        return;
      }
      fetch(this.wordUrl)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = '培养方案.docx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => console.error('Error fetching PDF:', error));
    },
    handleReject() {
      this.rejectVisibleForm = true;
    },
    handleRejectReason() {
      this.$http.post("/rejectFromCollege", { major_id: this.$route.params.major_id, use_state: this.user_state })
          .then(() => {
            this.$message.success("已打回！");
          })
          .catch(() => {
            this.$message.error("出现问题！");
          });
      this.rejectVisibleForm = false;
    },
    handlePass() {
      this.$http.post("/passFromCollege", { major_id: this.$route.params.major_id })
          .then(() => {
            this.$message.success('已通过！');
          })
          .catch(() => {
            this.$message.error("出现问题！");
          });
    },
    handleCheckboxChange(label) {
      this.user_state[label] = 3;
    }
  }
}
</script>

<style scoped>
:deep(.vue-office-pdf-wrapper) {
  padding: 0 !important;
  background-color: transparent !important;
}
.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.checkbox-column {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-start; /* 左对齐 */
}
</style>
