<template>
  <div>
    <div class="top">
      <div style="margin-left: 1%">
        <img src="@/assets/picture/tg_logo_p.png" alt="" style="height: 40px;margin:4%">
      </div>
      <div style="display: flex;justify-content: center;align-items: center;width: 20%;color:white;font-size: 16px">
        欢迎使用培养方案修订系统！
      </div>
      <div style="width: 80%;display: flex;flex-direction: row-reverse">
        <div>
          <el-dropdown style="margin-top: 35%">
            <span class="el-dropdown-link" style="color: white">admin<i class="el-icon-arrow-down el-icon--right"></i></span>
            <el-dropdown-menu>
              <el-dropdown-item>
                <router-link to="/password" style="text-decoration: none; color: #606266;">修改密码
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="display: flex; justify-content: center; align-items: center; width: 3%;">
          <el-icon name="user-solid" style="color: white;font-size: 20px"></el-icon>
        </div>
        <div style="margin-right: 1%;display: flex;justify-content: center;align-items: center;width: 10%;">
          <el-select v-model="enrollment_year">
            <el-option @click="changeEnrollment_year(enrollment_year)" v-for="enrollment_year in enrollment_year_list" :key="enrollment_year" :value="enrollment_year"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div style="display: flex;flex-direction: row;">
      <div class="catalogue">
        <el-menu
            style="height: 100%; width: 100%;"
            background-color="#71246B"
            text-color="#ffffff"
        >
          <router-link to="/school-admin/menu">
            <el-menu-item index="1" style="color: white" align="left">
              <i class="el-icon-s-home" style="color: white"></i>首页
            </el-menu-item>
          </router-link>
          <router-link to="/school-admin/collegeAndMajorManage">
            <el-menu-item index="2" style="color: white" align="left">
              <i class="el-icon-s-unfold" style="color: white"></i>学院和专业管理
            </el-menu-item>
          </router-link>
          <router-link to="/school-admin/employeeManage">
            <el-menu-item index="3" style="color: white" align="left">
              <i class="el-icon-user-solid" style="color: white"></i>人员管理
            </el-menu-item>
          </router-link>
          <el-submenu index="4" align="left">
            <template slot="title"><i class="el-icon-s-order" style="color: white"></i><span>审核</span></template>
            <router-link
                v-for="(college, index) in collegeList"
                :to="`/school-admin/check/${college.college_id}`"
                :key="index"
            >
              <el-menu-item :index="`4.${index + 1}`" style="color: white" align="left" class="item">
                {{ shortenName(college.college_name) }}
              </el-menu-item>
            </router-link>
          </el-submenu>
        </el-menu>
      </div>
      <div class="right">
        <router-view :majorList="majorList" :college_name="getCollegeName($route.params.college_id)" />
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      enrollment_year: localStorage.getItem('enrollment_year'),
      enrollment_year_list: [2023, 2024],
      collegeList: [],
      majorList: []
    };
  },
  created() {
    this.$http.get('/getCollegeList')
        .then(response=>{
          this.collegeList = response.data.data.collegeList;
        })
    const college_id = this.$route.params.college_id;
    if (college_id) {
      this.fetchMajors(college_id);
    }
  },
  methods: {
    shortenName(name) {
      if (name.length > 10) {
        return name.slice(0, 10) + '...';
      }
      return name;
    },
    handleLogout() {
      this.$router.push("/");
    },
    changeEnrollment_year(enrollment_year) {
      localStorage.setItem('enrollment_year', this.enrollment_year);
    },
    fetchMajors(college_id) {
      this.$http.get('/getMajorList')
          .then(response => {
            if (response.data.success && response.data.code === 20000) {
              const allMajors = response.data.data.majorList;
              this.majorList = allMajors.filter(major => major.college_id === parseInt(college_id));
            } else {
              this.majorList = [];
            }
          })
          .catch(() => {
            this.majorList = [];
          });
    },
    getCollegeName(college_id) {
      const college = this.collegeList.find(c => c.college_id === college_id);
      return college ? college.college_name : '';
    }
  },
  watch: {
    '$route'(to, from) {
      const college_id = to.params.college_id;
      this.fetchMajors(college_id);
    }
  }
};
</script>


<style>
.top {
  height: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #71246B;
}
.catalogue {
  width: 15%;
  height: 100vh;
  min-width: 180px; /* 设置一个固定的最小宽度 */
  max-width: 18%; /* 限制最大宽度，以防止内容过多时过度扩展 */
  display: flex;
  flex-direction: row;
  background-color: #71246B;
}
.right {
  width: 100%;
  height: 100vh;
  background-color: rgb(242, 243, 245);
}
.el-menu {
  border-right-width: 0;
}
.item {
  background-color: #83467F !important;
}
.el-submenu .el-menu-item {
  min-width: 0 !important;
}
</style>
