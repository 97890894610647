<template>
  <div style="width: 100%;display: flex;flex-direction: column; justify-content: center;align-items: center">
    <h4>各必修、限选课程学时、学分分配统计表</h4>
    <div style="width: 98%;display: flex;justify-content: center">
      <el-table show-summary sum-text="A+B+C+Z" border :data="tableData" :min-height="tableHeight" :max-height="tableHeight">
        <el-table-column prop="course_type" label="类型">
        </el-table-column>
        <el-table-column prop="total_credits" label="总学分">
        </el-table-column>
        <el-table-column prop="total_duration" label="总学时">
        </el-table-column>
        <el-table-column label="学时分配" width="300">
          <el-table-column prop="total_theoretical_duration" label="理论" width="75">
          </el-table-column>
          <el-table-column prop="total_experiment_duration" label="实验" width="75">
          </el-table-column>
          <el-table-column prop="total_design_duration" label="设计" width="75">
          </el-table-column>
        </el-table-column>
        <el-table-column label="各学期周学分分配">
          <el-table-column prop="semester1" label="一" width="70"></el-table-column>
          <el-table-column prop="semester2" label="二" width="70"></el-table-column>
          <el-table-column prop="semester3" label="三" width="70"></el-table-column>
          <el-table-column prop="semester4" label="四" width="70"></el-table-column>
          <el-table-column prop="semester5" label="五" width="70"></el-table-column>
          <el-table-column prop="semester6" label="六" width="70"></el-table-column>
          <el-table-column prop="semester7" label="七" width="70"></el-table-column>
          <el-table-column prop="semester8" label="八" width="70"></el-table-column>
        </el-table-column>
        <el-table-column label="开课门数" prop="courses_num" width="50">
        </el-table-column>
      </el-table>
    </div>
    <div style="width:98%; display: flex;flex-direction: column;justify-content: left;align-items: flex-start">
      <h6 style="margin:10px">一、此表不包含专业选修课和通识选修课；</h6>
      <h6 style="margin:10px">二、如专业不分方向，表中“专业方向课”改为“专业课Z”；"A+B+C+Z1"改为“A+B+C+Z”，并删除Z2行</h6>
      <h6 style="margin:10px">三、此表由课程数据自动汇总，无法修改；如想修改，请前往课程板块修改</h6>
    </div>
    <div style="margin-top: 10px;margin-bottom: 10px;display: flex;justify-content: flex-end;">
      <el-button type="primary" @click="handleSubmit()"  style="background-color: #71246B;">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      courses:[],
      tableData:[
        {
          course_type:'通识必修课A',
          total_credits:0,
          total_duration:0,
          total_theoretical_duration:0,
          total_experiment_duration:0,
          total_design_duration:0,
          semester1:0,
          semester2:0,
          semester3:0,
          semester4:0,
          semester5:0,
          semester6:0,
          semester7:0,
          semester8:0,
          courses_num:-10,
        },
        {
          course_type:'学科基础课B',
          total_credits:0,
          total_duration:0,
          total_theoretical_duration:0,
          total_experiment_duration:0,
          total_design_duration:0,
          semester1:0,
          semester2:0,
          semester3:0,
          semester4:0,
          semester5:0,
          semester6:0,
          semester7:0,
          semester8:0,
          courses_num:0,
        },{
          course_type:'专业基础课C',
          total_credits:0,
          total_duration:0,
          total_theoretical_duration:0,
          total_experiment_duration:0,
          total_design_duration:0,
          semester1:0,
          semester2:0,
          semester3:0,
          semester4:0,
          semester5:0,
          semester6:0,
          semester7:0,
          semester8:0,
          courses_num:0,
        },{
          course_type:'专业方向课Z',
          total_credits:0,
          total_duration:0,
          total_theoretical_duration:0,
          total_experiment_duration:0,
          total_design_duration:0,
          semester1:0,
          semester2:0,
          semester3:0,
          semester4:0,
          semester5:0,
          semester6:0,
          semester7:0,
          semester8:0,
          courses_num:0,
        },
      ],
      tableHeight:null
    }
  },
  mounted() {
    this.tableHeight = window.innerHeight - 350;
  },
  created() {
    // 发起HTTP请求获取课程数据
    this.$http.post('/getCourses', { major_id: localStorage.getItem('major_id'), enrollment_year: localStorage.getItem('enrollment_year') })
        .then(response => {
          // 将课程数据保存到courses数组中
          this.courses = response.data.data.courses;
          // 调用更新表格数据的方法
          this.updateTableData();
        })
        .catch(error => {
          console.error('获取课程列表失败！', error);
          // 处理获取数据失败的情况
        });
  },
  methods: {
    updateTableData() {
      // 遍历课程数据，更新表格数据
      this.courses.forEach(course => {
        let tableItem = this.tableData.find(item => item.course_type === course.course_type);
        if (tableItem) {
          tableItem.total_credits += course.credits;
          tableItem.total_duration += course.total_duration;
          tableItem.total_theoretical_duration += course.theoretical_duration;
          tableItem.total_experiment_duration += course.experiment_duration;
          tableItem.total_design_duration += course.design_duration;
          tableItem.courses_num++;
          switch (course.semester) {
            case 1:
              tableItem.semester1 += course.credits;
              break;
            case 2:
              tableItem.semester2 += course.credits;
              break;
            case 3:
              tableItem.semester3 += course.credits;
              break;
            case 4:
              tableItem.semester4 += course.credits;
              break;
            case 5:
              tableItem.semester5 += course.credits;
              break;
            case 6:
              tableItem.semester6 += course.credits;
              break;
            case 7:
              tableItem.semester7 += course.credits;
              break;
            case 8:
              tableItem.semester8 += course.credits;
              break;
            default:
              break;
          }
        }
      });
    },
    handleSubmit() {
      this.$http.post('/finishTable2',{major_id:localStorage.getItem('major_id')})
          .then(response=>{
            this.$message.success("保存成功！")
          })
          .catch(error=>{
            this.$message.error("保存失败！")
          })
    }
  }
}
</script>
