<template>
  <div style="margin: 1%; display: flex; flex-direction: column;">
    <div style="display: flex; flex-direction: row; justify-content: left;">
      <i class="iconfont icon-normal" style="font-size: 12px; margin-top: -3px; color: grey;"></i>
      <div style="font-size: 12px; color: grey; margin-top: -5px;">表格编排</div>
    </div>
    <div style=" background-color: white; margin-top: 5px;">
      <el-tabs v-model="activeName" type="border-card" @tab-click="tabClick">
        <el-tab-pane v-for="table in tables" :key="table.name" :label="table.label" :name="table.name">
        </el-tab-pane>
        <keep-alive>
          <router-view/>
        </keep-alive>
      </el-tabs>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: '',
      flag: '',
      tables: [
        { name: '/major-admin/table/table1', label: '表1' },
        { name: '/major-admin/table/table2', label: '表2' },
        { name: '/major-admin/table/table3', label: '表3' },
        { name: '/major-admin/table/table4', label: '表4' },
        { name: '/major-admin/table/table5', label: '表5' },
        { name: '/major-admin/table/table6', label: '表6' },
        { name: '/major-admin/table/table7', label: '表7' },
        { name: '/major-admin/table/table8', label: '表8' }
      ]
    };
  },
  created() {
    if (!localStorage.getItem('flag')) {
      this.flag = '/major-admin/table/table1';
      this.activeName = '/major-admin/table/table1';
      if (this.$route.path !== this.activeName) {
        this.$router.push({ path: this.activeName });
      }
    } else {
      this.flag = localStorage.getItem('flag');
      this.activeName = this.flag; // 设置activeName为localStorage中存储的值
      if (this.$route.path !== this.flag) {
        this.$router.push({ path: this.flag });
      }
    }
  },

  methods: {
    tabClick(tab) {
      if(this.flag != this.activeName){
        this.$router.push({ path: this.activeName });
        this.flag = this.activeName
        localStorage.setItem('flag',this.flag)
      }
    },
  },
}
</script>

<style>
.el-tabs__content{
  padding: 0 !important;
}
</style>
